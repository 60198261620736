import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button, Progress, Card, CardBody, CardFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import DetectionHeader from './template/DetectionHeader';
import { setDetectionFilter } from '../redux/actions/detectionActions';
import { detectionRequest, updateDetectionRequest } from '../helpers/requests';
import Notification from '../components/notification/Notification';
import { Activity, AlertTriangle } from 'react-feather';
import moment from 'moment';
import Paging from './template/Paging';
const Detections = () => {
    const dispatch = useDispatch();
    const filter = useSelector((state)=> state.detection.filter);
    const loader = useSelector((state)=> state.detection.loader);
    const data = useSelector((state)=> state.detection.data);
    const old = useSelector((state)=> state.detection.old);
    const success = useSelector((state)=> state.detection.success);
    const error = useSelector((state)=> state.detection.error);
    const user = useSelector((state)=> state.auth.user);
    const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

    const [dataText, setDataText] = useState([]);
    const [oldText, setOldText] = useState([]);

    const setNotificationType = (type) => {
        if(type != filter.type){
            getNotifications({...filter, type:type, page:1});
        }
        dispatch(setDetectionFilter({...filter, type:type, page:1}));
    }

    const setPage = (val) => {
        getNotifications({...filter, page:val});
        dispatch(setDetectionFilter({...filter, page:val}));
    }

    const markAsRead = (val) => {
        val.status = 0;
        var requestData = {
            params: val,
            dispatch: dispatch
        };
        updateDetectionRequest(requestData);
        setTimeout(()=>{
            getNotifications();
        }, 1000);
    }

    const generateText = (notification) => {
        var slot = null;
        var vehicle = null;
        user.access[0].floors[0].sections.map((val, index)=> {
            if(val.slot == "Slot "+notification.section){
                slot = val.slot;
                vehicle = val.label;
            }
        });

        if(!slot){
            slot = "Slot 1";
            vehicle = "Car 1";
        }

        if(notification.activity == 0){
            return vehicle + " has been removed from " + slot;
        }else{
            return  "A vehicle has been replaced at " + slot;
        }
    }

    useEffect(()=>{
        var _oldText = [];
        var _dataText = [];
        if(data.length > 0){
            data.map((val)=>{
                var text = generateText(val);
                _dataText.push(text);
                return val;
            })
        }
        if(old.length > 0){
            old.map((val)=>{
                var text = generateText(val);
                _oldText.push(text);
                return val;
            })
        }
        setDataText(_dataText);
        setOldText(_oldText);
    }, [old, data])



    // useEffect(()=>{
    //     getNotifications(filter.type);
    // }, [filter])

    const getNotifications = (newFilter = null) => {
        var _filter = newFilter?newFilter: filter;
        var requestData = {
            type: _filter.type,
            params: {
                page: _filter.page,
                limit: _filter.limit,
                status: _filter.type==_filter.types[0]? 1 : 0
            },
            dispatch: dispatch
        };
        detectionRequest(requestData);
    }

    useEffect(()=>{
        getNotifications(filter);
    }, [])

    return (
        <Fragment>
            <Row noGutters>
                <Col>
                <h4 className="my-3">Detections</h4>
                </Col>
            </Row>
            <Row noGutters>
                <Col>
                    <DetectionHeader icon="star" title="Notifications" type={filter.type} types={filter.types} setType={(val) => setNotificationType(val)}/>
                </Col>
            </Row>
            <Row>
                <Col style={{height: "20px"}}>
                    {
                        loader &&
                        <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
                    }
                </Col>
            </Row>
            
            <Row noGutters className="mb-2">
                <Col md={12}>
                    <Card>
                        <CardBody>
                            { filter.type == filter.types[0] && 
                                data.map((notification, index)=>{
                                    return (
                                        <Notification
                                            time={rtf.format(moment(notification.created_at).diff(new Date(), "days"), "day")}
                                            unread={true}
                                            key = {"data_"+index}
                                            to={"/alerts"}
                                        >
                                            {/* {notification.activity == 0 ? <AlertTriangle className="mr-3"/> : <Activity className="mr-3"/>} |  */}
                                            {dataText[index]}
                                            <Button color="primary" className="btn btn-sm btn-danger" style={{float: "right"}} onClick={()=>markAsRead(notification)}>Mark as read</Button>
                                        </Notification>
                                    )
                                })
                            }
                            { filter.type == filter.types[1] && 
                                old.map((notification, index)=>{
                                    return (
                                        <Notification
                                            time={rtf.format(moment(new Date()).diff(notification.created_at, "days"), "day")}
                                            unread={false}
                                            key = {"old_"+index}
                                            to={"/alerts"}
                                        >
                                            {/* <span>{notification.activity == 0 ? <AlertTriangle className="mr-3"/> : <Activity className="mr-3"/>}</span>  */}
                                            { oldText[index] }
                                        </Notification>
                                    )
                                })
                            }
                        </CardBody>
                        {
                            filter.total > 0 &&
                            <CardFooter className="align-center">
                                <Paging {...filter} setPage={(output)=>setPage(output)}></Paging>
                            </CardFooter>
                        }
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

//<h4 className="m-0">{data?.mmm_today?.mean|0}</h4>
export default Detections;

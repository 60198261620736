import React from 'react';
import { Row, Col } from 'reactstrap';

const CenteralTendencyExplain = ({tendency}) => {
    return(
        <Row>
            <Col>
              {
                tendency === "mean" &&
                <p className="m-0 p-0">Mean is the average visitors per time period. This metric can give us idea about the total number of average visitors per selected time slot.</p>
              }

              {
                tendency === "median" &&
                <p className="m-0 p-0">The median is the middle value when visitors data  is ordered from least to greatest. It gives us the analysis of the peaks in the data.</p>
              }

              {
                tendency === "mode" &&
                <p className="m-0 p-0">The mode is the value that appears most frequently in our visitors data.  The data may have one mode, more than one mode, or no mode at all.</p>
              }
            </Col>
          </Row>
    )
}

export default CenteralTendencyExplain;
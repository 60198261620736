import { actionTypes } from "../constants/action-types";
import moment from 'moment';

const initialState = {
    data: [],
    old: [],
    filter: {
        type: "new",
        types: ["new", "history"],
        store: null,
        stores: [],
        floor: null,
        floors: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day"),
        page: 1,
        limit: 10,
        pages: 0,
        total: 0
    },
    loader: false,
    success: null,
    error: null
}

export const detectionReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_DETECTION:
            return { ...state, data: payload, loader: false };
        case actionTypes.SET_DETECTION_OLD:
            return { ...state, old: payload, loader: false };
        case actionTypes.SET_DETECTION_UPDATE:
            var data = state.data.slice();
            data = data.filter((val)=> val._id != payload._id);
            return { ...state, data: data, loader: false };
        case actionTypes.SET_DETECTION_LOADER:
                return { ...state, loader: payload };
        case actionTypes.SET_DETECTION_FILTER:
                return { ...state, filter: {...state.filter, ...payload} };
        case actionTypes.SET_DETECTION_SUCCESS:
                return { ...state, success: payload };
        case actionTypes.SET_DETECTION_ERROR:
                return { ...state, error: payload };
        default:
            return state;
    }
}
import {combineReducers} from 'redux';
import {authReducer} from './authReducer';
import {summaryReducer} from './summaryReducer';
import {footfallReducer} from './footfallReducer';
import {heatmapReducer} from './heatmapReducer';
import {categoryReducer} from './categoryReducer';
import {vehicleReducer} from './vehicleReducer';
import {insightReducer} from './insightReducer';
import {sectionReducer} from './sectionReducer';
import { comparisonReducer } from './comparisonReducer';
import { detectionReducer } from './detectionReducer';

const reducers = combineReducers({
    auth: authReducer,
    summary: summaryReducer,
    footfall: footfallReducer,
    heatmap: heatmapReducer,
    category: categoryReducer,
    vehicle: vehicleReducer,
    insight: insightReducer,
    sections: sectionReducer,
    compare: comparisonReducer,
    detection: detectionReducer
})

export default reducers;
import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Progress } from 'reactstrap';
import CardWithPie from '../components/production/CardWithPie';
import LineGraph from '../components/production/LineGraph';
import { useSelector, useDispatch } from 'react-redux';
import { footfallRequest, footfallGraphRequest } from '../helpers/requests';
import { setFootfallFilter, setFootfallLoader } from '../redux/actions/footfallActions';
import moment from 'moment';
import HeaderFilter from './template/HeaderFilter';
import FormFilter from './template/FormFilter';
import ReportsHeader from './template/ReportsHeader';
import ReportsExcel from './template/ReportsExcel';
import ReportsPDF from './template/ReportsPDF';

const downloadOptions = [
  {value: "excel", label: "Excel"},
  {value: "pdf", label: "PDF"}
];

const Reporting = () => {
  const dispatch = useDispatch();
  var visitorsCount = useSelector((state)=> state.footfall.data);
  var visitorsGraph = useSelector((state)=> state.footfall.graphs);
  var filter = useSelector((state)=> state.footfall.filter);
  var loader = useSelector((state)=> state.footfall.loader);
  const user = useSelector((state)=> state.auth.user);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  const [isSelected, setIsSelected] = useState(filter.type);
  const setSelected = (selection) =>{
    setIsSelected(selection);
    var startDate;
    var endDate;
    try{
      if(selection == "today"){
          startDate = moment(new Date()).startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "yesterday"){
          startDate = moment(new Date()).subtract(1, "day").startOf("day");
          endDate = moment(new Date()).subtract(1, "day").endOf("day");
      }else if(selection == "this_week"){
          startDate = moment(new Date()).subtract(6, "day").startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "this_month"){
        startDate = moment(new Date()).subtract(30, "day").startOf("day");
        endDate = moment(new Date()).endOf("day");
      }
      dispatch(setFootfallFilter({...filter, start: startDate, end: endDate, type: selection}));
      var requestData = {
        params: {
          fid: [filter.floor? filter.floor._id:filter.store.floors[0]._id],
          sid: filter.store? filter.store.value: stores[0].value, 
          start: startDate, 
          end: endDate
        }, 
        dispatch: dispatch
      }
      dispatch(setFootfallLoader(true));
      footfallRequest(requestData);
      footfallGraphRequest(requestData);
    }catch(e){
    }
  }
  const setStartDate = (startDate) =>{
    dispatch(setFootfallFilter({...filter, start: startDate, type: null}));
  }
  const setEndDate = (endDate) =>{
    dispatch(setFootfallFilter({...filter, end: endDate, type: null}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    dispatch(setFootfallFilter({...filter, store: store, stores: stores, floor: tempFloors[0], floors: tempFloors, type: null}));
  }
  const setFloor = (floor) =>{
    dispatch(setFootfallFilter({...filter, floor: floor, type: null}));
  }
  const setDownloadOption = (option) => {
    dispatch(setFootfallFilter({...filter, downloadOption: option}));
  }

  const getFootfall = () => {
    var requestData = {
      params: {
        fid: [filter.floor? filter.floor._id:stores[0].floors[0]._id],
        sid: filter.store? filter.store.value: stores[0].value, 
        start: filter.start, 
        end: filter.end
      }, 
      dispatch: dispatch
    }
    dispatch(setFootfallLoader(true));
    footfallRequest(requestData);
    footfallGraphRequest(requestData);
  }

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getFootfall();
    }, 200);
  }, []);

  return(
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Reports</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <ReportsHeader icon="star" title="Export Analytics" value={""} downloadOption={filter.downloadOption} downloadOptions={filter.downloadOptions} setDownloadOption={(val) => setDownloadOption(val)}/>
        </Col>
      </Row>
      <FormFilter filter={filter} setStore={(e)=>setStore(e)} setFloor={(e)=>setFloor(e)} setStartDate={(e)=>setStartDate(e)} setEndDate={(e)=>setEndDate(e)} submit={()=>getFootfall()}/>
      <Row>
        <Col style={{height: "20px"}}>
          {
            loader &&
            <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
          }
        </Col>
      </Row>
      {
        filter.downloadOption.value === "excel" &&
        <ReportsExcel/>
      }
      {
        filter.downloadOption.value === "pdf" &&
        <ReportsPDF/>
      }
    </Fragment>
  )
}

export default Reporting;

import { actionTypes } from "../constants/action-types";
import moment from 'moment';

const getWeeks = (year) => {
    var temp = [];
    var week = 52;
    if(year === new Date().getFullYear()){
        week = moment(new Date()).week();
    }else{
        week = moment(year+"/12/31").week();
        if(week === 1){
            week = 52;
        }
    }
    for(var i = week; i>0; i--){
        var start = moment(year, "YYYY").week(i).startOf('week');
        var end = moment(year, "YYYY").week(i).endOf('week');
        var obj = {value: i, start: start, end: end, label: start.format("MMM Do")+"-"+end.format("MMM Do")};
        temp.push(obj);
    }
    return temp;
}
const weeks = getWeeks(new Date().getFullYear());

const months = [
    {value: 1, label: "January", short: "Jan"},
    {value: 2, label: "Februaru", short: "Feb"},
    {value: 3, label: "March", short: "Mar"},
    {value: 4, label: "April", short: "Apr"},
    {value: 5, label: "May", short: "May"},
    {value: 6, label: "June", short: "Jun"},
    {value: 7, label: "July", short: "Jul"},
    {value: 8, label: "August", short: "Aug"},
    {value: 9, label: "September", short: "Sep"},
    {value: 10, label: "October", short: "Oct"},
    {value: 11, label: "November", short: "Nov"},
    {value: 12, label: "December", short: "Dec"}
];
const initialState = {
    data: [],
    insights: null,
    loading: false,
    filter: {
        type: {value: "daily", label: "Daily"},
        types: [
            {value: "daily", label: "Daily"},
            {value: "weekly", label: "Weekly"},
            {value: "monthly", label: "Monthly"},
            {value: "yearly", label: "Yearly"}
        ],
        week: weeks[0],
        weeks: weeks,
        months: months,
        month: months[new Date().getMonth()],
        year: {value: new Date().getFullYear(), label: new Date().getFullYear()},
        years: new Array(5).fill(0).map((val, index) => {
            return {value: new Date().getFullYear()-index, label: new Date().getFullYear()-index}
        }),
        stores: [],
        store: null,
        date: moment(new Date()),
        startDate: new Date(),
        endDate: new Date()
    },
    previous: {
        type: {value: "daily", label: "Daily"},
        types: [
            {value: "daily", label: "Daily"},
            {value: "weekly", label: "Weekly"},
            {value: "monthly", label: "Monthly"},
            {value: "yearly", label: "Yearly"}
        ],
        week: weeks[0],
        weeks: weeks,
        months: months,
        month: months[new Date().getMonth()],
        year: {value: new Date().getFullYear(), label: new Date().getFullYear()},
        years: new Array(5).fill(0).map((val, index) => {
            return {value: new Date().getFullYear()-index, label: new Date().getFullYear()-index}
        }),
        stores: [],
        store: null,
        date: moment(new Date()),
        startDate: new Date(),
        endDate: new Date()
    },
}

export const insightReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_INSIGHT:
            if(state.filter.type.value === "yearly"){
                payload.top_months.forEach((value, index)=>{
                    value._id = ("0"+(parseInt(value._id)-1)).slice(-2);
                })
            }
            return { ...state, insights: payload, data: payload.peak_hours, previous: state.filter };
        case actionTypes.SET_INSIGHT_PROGRESS:
            return { ...state, loading: payload };
        case actionTypes.SET_INSIGHT_DATA:
            return { ...state, data: payload };
        case actionTypes.SET_INSIGHT_FILTER:
            if(state.filter.type.value === "weekly" && state.filter.year.value !== payload.year.value){
                payload.weeks = getWeeks(payload.year.value);
                payload.week = payload.weeks[0];
            } 
            return { ...state, filter: payload };
        default:
            return state;
    }
}


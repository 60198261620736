import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const Forecasting = () => (
  
      <Row className="align-items-center justify-content-between">
        <Col lg={6}>
            
        </Col>
        <Col lg={6} className="pl-lg-4 my-5 text-center text-lg-left">
         
        </Col>
      </Row>
);

export default Forecasting;

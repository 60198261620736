import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Progress } from 'reactstrap';
import CardWithPie from '../components/production/CardWithPie';
import LineGraph from '../components/production/LineGraph';
import { useSelector, useDispatch } from 'react-redux';
import { footfallRequest, footfallGraphRequest } from '../helpers/requests';
import { setFootfallFilter, setFootfallLoader } from '../redux/actions/footfallActions';
import moment from 'moment';
import HeaderFilter from './template/HeaderFilter';
import FormFilter from './template/FormFilter';

const Footfall = () => {
  const dispatch = useDispatch();
  var visitorsCount = useSelector((state)=> state.footfall.data);
  var visitorsGraph = useSelector((state)=> state.footfall.graphs);
  var filter = useSelector((state)=> state.footfall.filter);
  var loader = useSelector((state)=> state.footfall.loader);
  const user = useSelector((state)=> state.auth.user);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  const [isSelected, setIsSelected] = useState(filter.type);
  const setSelected = (selection) =>{
    setIsSelected(selection);
    var startDate;
    var endDate;
    try{
      if(selection == "today"){
          startDate = moment(new Date()).startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "yesterday"){
          startDate = moment(new Date()).subtract(1, "day").startOf("day");
          endDate = moment(new Date()).subtract(1, "day").endOf("day");
      }else if(selection == "this_week"){
          startDate = moment(new Date()).subtract(6, "day").startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "this_month"){
        startDate = moment(new Date()).subtract(30, "day").startOf("day");
        endDate = moment(new Date()).endOf("day");
      }
      dispatch(setFootfallFilter({...filter, start: startDate, end: endDate, type: selection}));
      var requestData = {
        params: {
          fid: [filter.floor? filter.floor._id:filter.store.floors[0]._id],
          sid: filter.store? filter.store.value: stores[0].value, 
          start: startDate, 
          end: endDate
        }, 
        dispatch: dispatch
      }
      dispatch(setFootfallLoader(true));
      footfallRequest(requestData);
      footfallGraphRequest(requestData);
    }catch(e){
    }
  }
  const setStartDate = (startDate) =>{
    dispatch(setFootfallFilter({...filter, start: startDate, type: null}));
  }
  const setEndDate = (endDate) =>{
    dispatch(setFootfallFilter({...filter, end: endDate, type: null}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    dispatch(setFootfallFilter({...filter, store: store, stores: stores, floor: tempFloors[0], floors: tempFloors, type: null}));
  }
  const setFloor = (floor) =>{
    dispatch(setFootfallFilter({...filter, floor: floor, type: null}));
  }

  const getFootfall = () => {
    var requestData = {
      params: {
        fid: [filter.floor? filter.floor._id:stores[0].floors[0]._id],
        sid: filter.store? filter.store.value: stores[0].value, 
        start: filter.start, 
        end: filter.end
      }, 
      dispatch: dispatch
    }
    dispatch(setFootfallLoader(true));
    footfallRequest(requestData);
    footfallGraphRequest(requestData);
  }

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getFootfall();
    }, 200);
  }, []);

  return (
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Visitors</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <HeaderFilter icon="star" title="Total Visitors" value={visitorsCount.total} selected={isSelected} setSelected={(val) => setSelected(val)}/>
        </Col>
      </Row>
      <FormFilter filter={filter} setStore={(e)=>setStore(e)} setFloor={(e)=>setFloor(e)} setStartDate={(e)=>setStartDate(e)} setEndDate={(e)=>setEndDate(e)} submit={()=>getFootfall()}/>
      
      <Row>
        <Col style={{height: "20px"}}>
        {
          loader &&
          <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
        }
       </Col>
      </Row>
      <Row noGutters className="mb-2">
        <Col md={3} className="pr-2">
          <CardWithPie data={visitorsCount.footfall} title={'Visitors'}/>
        </Col>
        <Col md={9}>
          <LineGraph seriesData={{data: visitorsGraph.total.data, labels: visitorsGraph.labels}} title="" titles={["Visitors"]}/>
        </Col>
      </Row>
      <Row noGutters className="mb-2">
        <Col md={3} className="pr-2">
          <CardWithPie data={visitorsCount.gender} title={'Gender'}/>
        </Col>
        <Col md={9}>
          <LineGraph seriesData={{data: visitorsGraph.gender.data, labels: visitorsGraph.labels}} title="" titles={["Male", "Female"]}/>
        </Col>
      </Row>
      <Row noGutters className="mb-2">
        <Col md={3} className="pr-2">
          <CardWithPie data={visitorsCount.age} title={'Age Groups'}/>
        </Col>
        <Col md={9}>
          <LineGraph seriesData={{data: visitorsGraph.age.data, labels: visitorsGraph.labels}} title="" titles={["Kids", "Adults"]}/>
        </Col>
      </Row>
      {/* <Row noGutters className="mb-2">
        <Col md={3} className="pr-2">
          <CardWithPie data={visitorsCount.mask} title={'Mask Index'}/>
        </Col>
        <Col md={9}>
          <LineGraph seriesData={{data: visitorsGraph.mask.data, labels: visitorsGraph.labels}}/>
        </Col>
      </Row> */}
      {/* <Row noGutters className="mb-2">
        <Col md={3} className="pr-2">
          <CardWithPie data={visitorsCount.dwell_time} title={'Dwell Time'}/>
        </Col>
        <Col md={9}>
          <LineGraph seriesData={{data: visitorsGraph.dwelltime.data, labels: visitorsGraph.labels}}/>
        </Col>
      </Row> */}
    </Fragment>
  )
}

export default Footfall;

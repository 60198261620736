import React, { Fragment } from 'react';
import {Card, CardBody, Row, Col, Button} from 'reactstrap';


const ReportsPDF = () => {

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody className="text-center">
                            <Button color="primary" className="px-4">Download PDF</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ReportsPDF;
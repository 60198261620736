import React, { Fragment, Component } from 'react';
import PageHeader from '../components/common/PageHeader';
import {Card, CardBody, ListGroup, ListGroupItem} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
const baseUrl = "http://13.229.214.173:5100/";

class Conversion extends Component{

  componentDidMount(){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({email: "admin@gmail.com", password: "admin@123"}),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer 12345"},
    };

    fetch(baseUrl+"users/authenticate", requestOptions).then(res => {
        return res.json();
    }).then(data => {
    }).catch(err =>{
    });
  }

  render(){
    return (
      <Fragment>
        <PageHeader
          title="POS system is not integrated yet"
          description="
            You can integrate your POS with Adlytic Dashboard to know your sales in comparison with the traffic of your store. 
          "
          className="mb-3"
        >
        </PageHeader>
        <Card>
          <FalconCardHeader title="How it will help" />
          <CardBody>
            <p>
              By integrating your POS you will be able to get following statistics on this dashboard.
            </p>
            <ListGroup flush>
              <ListGroupItem disabled>
                Total Sales
              </ListGroupItem>
              <ListGroupItem>
                Average Sales
              </ListGroupItem>
              <ListGroupItem>
                Storewise Sales
              </ListGroupItem>
              <ListGroupItem>
                Sales per square foot
              </ListGroupItem>
              <ListGroupItem>
                Sales per employee
              </ListGroupItem>
              <ListGroupItem>
                Products ranking
              </ListGroupItem>
              <ListGroupItem>
                Best performing products
              </ListGroupItem>
              <ListGroupItem>
                Best performing stores
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Fragment>
    )
  }
}

export default Conversion;

import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import Flex from '../../components/common/Flex';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { themeColors, getPosition, numberFormatter, getGrays } from '../../helpers/utils';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import AppContext from '../../context/Context';

const getOption = (data, labels, dataBackground, isDark) => {
  const grays = getGrays(isDark);
  return {
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      formatter: '{b1}: {c1}',
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      textStyle: { color: themeColors.dark },
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      }
    },
    xAxis: {
      type: 'category',
      data: labels,
      boundaryGap: false,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    series: [
      {
        type: 'bar',
        barWidth: '10px',
        barGap: '-100%',
        itemStyle: {
          color: grays['200'],
          barBorderRadius: 10
        },
        data: dataBackground,
        animation: false,
        emphasis: { itemStyle: { color: grays['200'] } }
      },
      {
        type: 'bar',
        barWidth: '10px',
        itemStyle: {
          color: themeColors.danger,
          barBorderRadius: 10
        },
        data: data,
        emphasis: { itemStyle: { color: themeColors.danger } },
        z: 10
      }
    ],
    grid: { right: 5, left: 10, top: 0, bottom: 0 }
  };
};

const BarComparison = ({ data, labels, cardTitle="Peak Trends" }) => {
    labels.push("Others");
  const { isDark } = useContext(AppContext);
  // Max value of data
  const yMax = Math.max(...data);
  const dataBackground = data.map(() => yMax);

  return (
    <Card className="h-md-100">
      <FalconCardHeader
        className="pb-0"
        title={
          <Fragment>
            {cardTitle}
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="text-400 ml-1"
              id="weeklySalesTooltip"
            />
            <UncontrolledTooltip placement="bottom" target="weeklySalesTooltip">
              Calculated based on total visitors within given duration
            </UncontrolledTooltip>
          </Fragment>
        }
        light={false}
        titleTag="h6"
      />
      <CardBody tag={Flex} align="end">
        <Row className="flex-grow-1">
          <Col>
            {
                data.map((val, index) => {
                    return(
                        <p className="p-0 m-0" key={"bar_"+index}>
                            <span className={`badge badge-soft-danger rounded-capsule mr-2`}>{labels[index]}</span> <span className={`badge badge-danger rounded-capsule mr-2`}>{numberFormatter(val, 0)}</span>
                        </p>
                    )}
                )
            }
          </Col>
          <Col xs="auto" className="pl-0">
            <ReactEchartsCore
              echarts={echarts}
              option={getOption(data, labels, dataBackground, isDark)}
              style={{ width: '8.5rem', height: '100%' }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

BarComparison.propTypes = { data: PropTypes.array.isRequired };

export default BarComparison;

import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button, Progress, Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { summaryRequest } from '../helpers/requests';
import { setSummaryFilter, setSummaryLoader } from '../redux/actions/summaryActions';
import SummaryCard from './template/SummaryCard';
import HeaderFilter from './template/HeaderFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardFooter from 'reactstrap/lib/CardFooter';
import CardHeader from 'reactstrap/lib/CardHeader';
import CenteralTendencyExplain from './template/CentralTendencyExplain';

const Summary = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state)=> state.summary.filter);
  const currentSummary = useSelector((state)=> state.summary.currentSummary);
  const data = useSelector((state)=> state.summary.data);
  const sofarSummary = useSelector((state)=> state.summary.sofarSummary);
  const loader = useSelector((state)=> state.summary.loader);
  const user = useSelector((state)=> state.auth.user);
  const [mmm, setMmm] = useState("mmm_today");
  const [mmmText, setMmmText] = useState("Hourwise");
  const [comparisonType, setComparisonType] = useState("current");
  const [tendency, setTendency] = useState("mean");
  const setSelected = (selection) => {
    dispatch(setSummaryFilter(selection));
  }

  useEffect(()=>{
    if(filter === "today"){
      setMmm("mmm_today");
      setMmmText("Hourwise");
    }else if(filter === "yesterday"){
      setMmm("mmm_yesterday");
      setMmmText("Hourwise");
    }else if(filter === "this_week"){
      setMmm("mmm_last_week");
      setMmmText("Daywise Weekly");
    }else if(filter === "this_month"){
      setMmm("mmm_last_month");
      setMmmText("Daywise Monthly");
    }else{
      setMmm("mmm_today");
    }
  }, [filter])

  useEffect(()=>{
    dispatch(setSummaryLoader(true));
    summaryRequest({dispatch});
  }, [1]);

  const repeatAgainForData = () => {
    setTimeout(()=>{
      if(loader){
        repeatAgainForData();
      }else{
        setSelected("today");
      }
    }, 500);
  }

  return (
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Summary</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <HeaderFilter icon="star" title="Total Stores" value={user? user.access.length<10? "0"+user.access.length : user.access.length  : "00"} selected={filter} setSelected={(val) => setSelected(val)}/>
        </Col>
      </Row>
      <Row>
        <Col style={{height: "20px"}}>
        {
          loader &&
          <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
        }
       </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-right">
          <Button color={comparisonType === 'full' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setComparisonType("full")}>Compare Full {filter==="today" || filter ==="yesterday" ? "Day" : filter==="this_week" ? "Week" : filter==="this_month" ? "Month" : "Day" }</Button> 
          <Button color={comparisonType === 'current' ? "danger" : "secondary"} className="btn-sm" onClick={()=>setComparisonType("current")}>Compare  {filter==="today" || filter ==="yesterday" ? "Hours" : filter==="this_week" ? "Days" : filter==="this_month" ? "Days" : "Hours" } So Far</Button>
        </Col>
      </Row>
      { comparisonType === "full" &&
        <Fragment>
          <Row noGutters>
            <Col>
              <div className="card-deck">
                <SummaryCard title="Total Visitors" total={currentSummary.value.total} icon="user" percent={currentSummary.percent.total} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.total}></SummaryCard>
                <SummaryCard title="Males" total={currentSummary.value.male} icon="male" percent={currentSummary.percent.male} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.male}></SummaryCard>
                <SummaryCard title="Females" total={currentSummary.value.female} icon="female" percent={currentSummary.percent.female} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.female}></SummaryCard>
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <div className="card-deck">
                <SummaryCard title="Kids" total={currentSummary.value.kid} icon="child" percent={currentSummary.percent.kid} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.kid}></SummaryCard>
                <SummaryCard title="Adults" total={currentSummary.value.adult} icon="male" percent={currentSummary.percent.adult} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.adult}></SummaryCard>
                <SummaryCard style={{visibility: "hidden"}} title="Average Dwell Time" total={currentSummary.value.mask} icon="clock" percent={currentSummary.percent.mask} prevTitle={currentSummary.previous_text} prevValue={currentSummary.previous.mask}></SummaryCard>
              </div>
            </Col>
          </Row>
        </Fragment>
      }
      { comparisonType === "current" &&
        <Fragment>
          <Row noGutters>
            <Col>
              <div className="card-deck">
                <SummaryCard title="Total Visitors" total={sofarSummary.value.total} icon="user" percent={sofarSummary.percent.total} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.total}></SummaryCard>
                <SummaryCard title="Males" total={sofarSummary.value.male} icon="male" percent={sofarSummary.percent.male} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.male}></SummaryCard>
                <SummaryCard title="Females" total={sofarSummary.value.female} icon="female" percent={sofarSummary.percent.female} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.female}></SummaryCard>
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <div className="card-deck">
                <SummaryCard title="Kids" total={sofarSummary.value.kid} icon="child" percent={sofarSummary.percent.kid} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.kid}></SummaryCard>
                <SummaryCard title="Adults" total={sofarSummary.value.adult} icon="male" percent={sofarSummary.percent.adult} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.adult}></SummaryCard>
                <SummaryCard style={{visibility: "hidden"}} title="Average Dwell Time" total={sofarSummary.value.mask} icon="clock" percent={sofarSummary.percent.mask} prevTitle={sofarSummary.previous_text} prevValue={sofarSummary.previous.mask}></SummaryCard>
              </div>
            </Col>
          </Row>
        </Fragment>
      }
      <Card>
        <CardHeader>
          <Row className="">
            <Col><h4 className="m-0">{tendency.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();})} | {mmmText}</h4></Col>
            <Col className="text-right">
              <Button color={tendency === 'mean' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setTendency("mean")}>Mean</Button> 
              <Button color={tendency === 'median' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setTendency("median")}>Median</Button>
              <Button color={tendency === 'mode' ? "danger" : "secondary"} className="btn-sm" onClick={()=>setTendency("mode")}>Mode</Button>
            </Col>
          </Row>
          <CenteralTendencyExplain tendency={tendency}/>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-center">
              <FontAwesomeIcon icon="user" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>Total Visitors</small></p>
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="male" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>Males</small></p>
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="female" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>Females</small></p>
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="child" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>Kids</small></p>
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="male" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>Adults</small></p>
            </Col>
            {/* <Col className="text-center">
              <FontAwesomeIcon icon="mask" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>With Mask</small></p>
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="mask" transform="up-1.5" color="danger"/>
              <p className="m-0"><small>No Mask</small></p>
            </Col> */}
          </Row>
          {
            tendency === "mean" &&
            <Row>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mean?.adult|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mean?.male|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mean?.female|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mean?.kid|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mean?.adult|0 : 0}</h4>
              </Col>
              {/* <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.mean?.mask|0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.mean?.noMask|0}</h4>
              </Col> */}
            </Row>
          }
          { tendency === "median" &&
            <Row>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.median?.adult|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.median?.male|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.median?.female|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.median?.kid|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.median?.adult|0 : 0}</h4>
              </Col>
              {/* <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.median?.mask|0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.median?.noMask|0}</h4>
              </Col> */}
            </Row>
          }
          { tendency === "mode" &&
            <Row>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mode?.adult|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mode?.male|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mode?.female|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mode?.kid|0 : 0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data? data[mmm]?.mode?.adult|0 : 0}</h4>
              </Col>
              {/* <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.mode?.mask|0}</h4>
              </Col>
              <Col className="text-center">
                <h4 className="m-0">{data?.mmm_today?.mode?.noMask|0}</h4>
              </Col> */}
            </Row>
          }
        </CardBody>
      </Card>
    </Fragment>
  );
}

//<h4 className="m-0">{data?.mmm_today?.mean|0}</h4>
export default Summary;

import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Store from '../components/production/Store';
import { useSelector } from 'react-redux';

const Stores = () => {
  const user = useSelector((state)=> state.auth.user);
  return(
    <Row>
      <Col>
        <Card className="mb-3">
          <FalconCardHeader title="Active Stores" light={false}></FalconCardHeader>
          <CardBody className="p-0">
            <Store stores={user.access} className="mb-3" />
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card className="mb-3">
          <FalconCardHeader title="Inactive Stores" light={false}></FalconCardHeader>
          <CardBody className="p-0">
            
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Stores;

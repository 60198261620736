import { actionTypes } from "../constants/action-types";

const initialState = {
    data: {today: [], yesterday:[], before_yesterday: [], this_week: [], last_week:[], this_month:[], last_month: []},
    currentSummary: {
        text: "Today",
        previous_text: "Yesterday",
        value: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
        previous: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
        percent: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
    },
    sofarSummary: {
        text: "Today",
        previous_text: "Yesterday",
        value: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
        previous: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
        percent: {"total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0},
    },
    filter: "today",
    loading: false
}

const calculateSummary = (currentSummary, current, previous, text, previous_text) => {
    let temp = currentSummary;
    temp.text = text;
    temp.previous_text = previous_text;
    temp.value = current;
    temp.previous = previous;
    temp.percent.total = calculatePercentage(current.total, previous.total);
    temp.percent.male = calculatePercentage(current.male, previous.male);
    temp.percent.female = calculatePercentage(current.female, previous.female);
    temp.percent.kid = calculatePercentage(current.kid, previous.kid);
    temp.percent.teen = calculatePercentage(current.teen, previous.teen);
    temp.percent.young = calculatePercentage(current.young, previous.young);
    temp.percent.adult = calculatePercentage(current.adult, previous.adult);
    temp.percent.mask = calculatePercentage(current.mask, previous.mask);
    temp.percent.noMask = calculatePercentage(current.noMask, previous.noMask);
    return temp;
}

const calculatePercentage = (current, previous) => {
    return previous ? Math.round((current - previous)*100/previous) : 0;
}

export const summaryReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_SUMMARY:
            Object.keys(payload).map((obj, index) => {
                if(payload[obj].length === 0){
                    let defaultObject = {"_id": null, "total": 0, "male": 0, "female": 0, "mask": 0, "noMask": 0, "kid": 0, "teen": 0, "young": 0, "adult": 0};
                    payload[obj] = [defaultObject];
                }
                return null;
            });
            var currentSummary;
            var sofarSummary;
            switch(state.filter){
                case "today":
                    currentSummary = calculateSummary(state.currentSummary, payload.today[0], payload.yesterday[0], "Today", "Yesterday");
                    sofarSummary = calculateSummary(state.sofarSummary, payload.today[0], payload.sofar_yesterday[0], "Today", "Yesterday");
                    break;
                case "yesterday":
                    currentSummary = calculateSummary(state.currentSummary, payload.yesterday[0], payload.before_yesterday[0], "Yesterday", "Last Day");
                    sofarSummary = calculateSummary(state.sofarSummary, payload.yesterday[0], payload.before_yesterday[0], "Yesterday", "Last Day");
                    break;
                case "this_week":
                    currentSummary = calculateSummary(state.currentSummary, payload.this_week[0], payload.last_week[0], "This Week", "Last Week");
                    sofarSummary = calculateSummary(state.sofarSummary, payload.this_week[0], payload.sofar_week[0], "This Week", "Last Week");
                    break;
                case "this_month":
                    currentSummary = calculateSummary(state.currentSummary, payload.this_month[0], payload.last_month[0], "This Month", "Last Month");
                    sofarSummary = calculateSummary(state.sofarSummary, payload.this_month[0], payload.sofar_month[0], "This Month", "Last Month");
                    break;
                default:
                    currentSummary = calculateSummary(state.currentSummary, payload.today[0], payload.yesterday[0], "Today", "Yesterday");
                    sofarSummary = calculateSummary(state.sofarSummary, payload.today[0], payload.sofar_yesterday[0], "Today", "Yesterday");
                    break;
            }
            return { ...state, data: payload, currentSummary: currentSummary, sofarSummary:sofarSummary };
        case actionTypes.SET_SUMARY_FILTER:
            var currentSummary;
            var sofarSummary;
            switch(payload){
                case "today":
                    currentSummary = calculateSummary(state.currentSummary, state.data.today[0], state.data.yesterday[0], "Today", "Yesterday");
                    sofarSummary = calculateSummary(state.sofarSummary, state.data.today[0], state.data.sofar_yesterday[0], "Today", "Yesterday");
                    break;
                case "yesterday":
                    currentSummary = calculateSummary(state.currentSummary, state.data.yesterday[0], state.data.before_yesterday[0], "Yesterday", "Last Day");
                    sofarSummary = calculateSummary(state.sofarSummary, state.data.yesterday[0], state.data.before_yesterday[0], "Yesterday", "Last Day");
                    break;
                case "this_week":
                    currentSummary = calculateSummary(state.currentSummary, state.data.this_week[0], state.data.last_week[0], "This Week", "Last Week");
                    sofarSummary = calculateSummary(state.sofarSummary, state.data.this_week[0], state.data.sofar_week[0], "This Week", "Last Week");
                    break;
                case "this_month":
                    currentSummary = calculateSummary(state.currentSummary, state.data.this_month[0], state.data.last_month[0], "This Month", "Last Month");
                    sofarSummary = calculateSummary(state.sofarSummary, state.data.this_month[0], state.data.sofar_month[0], "This Month", "Last Month");
                    break;
                default:
                    currentSummary = calculateSummary(state.currentSummary, state.data.today[0], state.data.yesterday[0], "Today", "Yesterday");
                    sofarSummary = calculateSummary(state.sofarSummary, state.data.today[0], state.data.sofar_yesterday[0], "Today", "Yesterday");
                    break;
            }
            return { ...state, filter: payload, currentSummary: currentSummary, sofarSummary: sofarSummary };
        case actionTypes.SET_CURRENT_SUMARY:
            return { ...state, currentSummary: payload };
        case actionTypes.SET_SUMARY_LOADER:
            return { ...state, loader: payload };
        default:
            return state;
    }
}
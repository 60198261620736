import {actionTypes} from '../constants/action-types'

export const setVehicle = (payload) => {
    return {
        type: actionTypes.SET_VEHICLE,
        payload: payload
    }
}

export const setVehicleGraph = (payload) => {
    return {
        type: actionTypes.SET_VEHICLE_GRAPH,
        payload: payload
    }
}

export const setVehicleFilter = (payload) => {
    return {
        type: actionTypes.SET_VEHICLE_FILTER,
        payload: payload
    }
}

export const setVehicleLoader = (payload) => {
    return {
        type: actionTypes.SET_VEHICLE_LOADER,
        payload: payload
    }
}


import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from '../../helpers/utils';
import { Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import StoreSummary from './StoreSummary';

const Store = ({ stores, ...rest }) => {

  return (
    <Card {...rest}>
      <FalconCardHeader title="Stores" />
      <CardBody className="fs--1">
        {
            isIterableArray(stores) &&
            stores.map((store, index) =>{ 
                return ( <StoreSummary {...store} key={"summry"+index} />)
            })
        }
      </CardBody>
    </Card>
  );
};

Store.propTypes = {
  stores: PropTypes.array,
  isEditable: PropTypes.bool
};

Store.defaultProps = { isEditable: false };

export default Store;

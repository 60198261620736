import { actionTypes } from "../constants/action-types";
import moment from 'moment';
const initialState = {
    data: [],
    filter: {
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day")
    },
    loader: false
}

export const sectionReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.UPDATE_SECTIONS:
            return { ...state, data: payload };
        case actionTypes.SECTIONS_LOADER:
            return { ...state, loader: payload };
        default:
            return state;
    }
}
import React from 'react';
import CardWithValue from '../../components/production/CardWithValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeColors } from '../../helpers/utils';

const SummaryCard = ({title, total, icon, percent, prevTitle, prevValue, style}) => {
    return(
        <CardWithValue rate="" title={title} color="success" linkText="" icon={icon} style={style}>
            <span style={{color: themeColors.dark}}>{total}</span><br/>
            <small style={{fontSize:"18px", color: themeColors.dark}}>
            <span className={`badge ${percent<0? "badge-soft-danger" : "badge-soft-success"} rounded-capsule mr-2`}>
                <FontAwesomeIcon icon={percent<0?"arrow-down" : "arrow-up"}/> {percent} {percent === "N/A" ? "" : "%"}
            </span>
            <span className="badge badge-soft-warning rounded-capsule">{prevTitle}: {prevValue}</span>
            </small>
        </CardWithValue>
    )
}
export default SummaryCard;
import {actionTypes} from '../constants/action-types'

export const setDetection = (payload) => {
    return {
        type: actionTypes.SET_DETECTION,
        payload: payload
    }
}

export const setDetectionOld = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_OLD,
        payload: payload
    }
}

export const setDetectionUpdate = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_UPDATE,
        payload: payload
    }
}

export const setDetectionFilter = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_FILTER,
        payload: payload
    }
}

export const setDetectionLoader = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_LOADER,
        payload: payload
    }
}

export const setDetectionSuccess = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_SUCCESS,
        payload: payload
    }
}

export const setDetectionError = (payload) => {
    return {
        type: actionTypes.SET_DETECTION_ERROR,
        payload: payload
    }
}


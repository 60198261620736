import React, { Fragment, useState, useEffect } from 'react';
import PageHeader from '../components/common/PageHeader';
import {Card, CardBody, Row, Col, Progress} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import heatmapImage from '../assets/img/heatmap.png';
import { useSelector, useDispatch } from 'react-redux';
import { heatmapRequest } from '../helpers/requests';
import Button from 'reactstrap/lib/Button';
import Heatmap  from './template/Heatmap';
// import hmData  from '../data/dashboard/heatmap';
import FormFilter from './template/FormFilter';
import { setHeatmapFilter, setHeatmapLoader, setHeatmap } from '../redux/actions/heatmapActions';
import moment from 'moment';
import HeaderFilter from './template/HeaderFilter';
import dye from '../assets/dye.png';
import base from '../assets/base.jpg';


const Heatmaps = () => {
  const dispatch = useDispatch();
  const heatmap = useSelector((state)=> state.heatmap.data);
  const heatmapStaff = useSelector((state)=> state.heatmap.staff);
  const heatmapMale = useSelector((state)=> state.heatmap.male);
  const heatmapFemale = useSelector((state)=> state.heatmap.female);
  var filter = useSelector((state)=> state.heatmap.filter);
  var loader = useSelector((state)=> state.heatmap.loader);
  const user = useSelector((state)=> state.auth.user);
  const [showMap, setShowMap] = useState(true);
  const [mapType, setMapType] = useState(1);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  var floors = [];
  if(stores.length){
    floors = stores[0].floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
  }

  const setSelected = (selection) =>{
    setType(selection);
    var startDate;
    var endDate;
    try{
      if(selection === "today"){
          startDate = moment(new Date()).startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection === "yesterday"){
          startDate = moment(new Date()).subtract(1, "day").startOf("day");
          endDate = moment(new Date()).subtract(1, "day").endOf("day");
      }else if(selection === "this_week"){
          startDate = moment(new Date()).subtract(6, "day").startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection === "this_month"){
        startDate = moment(new Date()).subtract(30, "day").startOf("day");
        endDate = moment(new Date()).endOf("day");
      }
      dispatch(setHeatmapFilter({...filter, start: startDate, end: endDate, type: selection}));
      var requestData = {
        params: {
          fid: filter.floor? filter.floor.value : null,
          sid: filter.store? filter.store.value: stores[0].value, 
          start: startDate, 
          end: endDate
        }, 
        dispatch: dispatch
      }
      getHeatmap(requestData);
    }catch(e){
    }
  }
  const setStartDate = (startDate) =>{
    dispatch(setHeatmapFilter({...filter, start: startDate, type: null}));
  }
  const setEndDate = (endDate) =>{
    dispatch(setHeatmapFilter({...filter, end: endDate, type: null}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    loadNewMap(tempFloors[0]._id, store._id);
    dispatch(setHeatmapFilter({...filter, store: store, stores: stores, floor: tempFloors[0], floors: tempFloors, type: null}));
  }
  const setFloor = (floor) =>{
    loadNewMap(floor._id);
    dispatch(setHeatmapFilter({...filter, floor: floor, type: null}));
  }

  const setType = (type) => {
    dispatch(setHeatmapFilter({...filter, type: null}));
  }

  const loadNewMap = (fid=null, sid=null, start=null, end = null) => {
    dispatch(setHeatmap([]));
    setShowMap(false);
    setTimeout(()=>{
      setShowMap(true);
      getHeatmap(fid, sid, start, end);
    }, 200)
  }

  const getHeatmap = (fid=null, sid=null, start=null, end = null) => {
    var requestData = {
      params: {
        fid: fid? fid: filter.floor? filter.floor.value: floors[0].value,
        sid: sid? sid: filter.store? filter.store.value: stores[0].value, 
        start: start? start: filter.start, 
        end: end? end: filter.end
      }, 
      dispatch: dispatch
    }
    dispatch(setHeatmapLoader(true));
    heatmapRequest(requestData);
    // setTimeout(()=>{
    //   dispatch(setHeatmap(hmData));
    // }, 5000);
  }
  // var sections = localStorage.getItem("sections")? JSON.parse(localStorage.getItem("sections")): null;
  // if(!sections){
  //   sections = new Array();
  // }
  // sections[sections.length] = new Array();
  // const clickPoint = (event) => {
  //   console.log(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
  //   sections[sections.length-1].push({x: event.nativeEvent.offsetX, y:event.nativeEvent.offsetY});
  //   localStorage.setItem("sections", JSON.stringify(sections));
  // }

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getHeatmap();
    }, 200);
  }, []);

  return (
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Heatmap</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <HeaderFilter icon="star" title={filter.store ? filter.store.label : ""} value={""} selected={filter.type} setSelected={(val) => setSelected(val)}/>
        </Col>
      </Row>
      <FormFilter filter={filter} setStore={(e)=>setStore(e)} setFloor={(e)=>setFloor(e)} setStartDate={(e)=>setStartDate(e)} setEndDate={(e)=>setEndDate(e)} submit={()=>getHeatmap()}/>
      
      <Row>
        <Col style={{height: "20px"}}>
        {
          loader &&
          <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
        }
       </Col>
      </Row>
      {/* <img src={filter.floor? filter.floor.image : ""} onClick={(event)=> clickPoint(event)}/> */}
      {/* <img src={base} onClick={(event)=> clickPoint(event)}/> */}
      <Row className="mb-3">
          <Col>
              <Card>
                  <CardBody>
                    {filter.store !== null && showMap &&
                      <Row>
                        <Col>
                          <Button className="px-5 mr-1 mb-1 selected-button" color={mapType===1?"danger":"secondary"} onClick={()=>setMapType(1)}>
                            Customers
                          </Button>
                          <Button className="px-5 mr-1 mb-1 selected-button" color={mapType===2?"danger":"secondary"} onClick={()=>setMapType(2)}>
                            Male
                          </Button>
                          <Button className="px-5 mr-1 mb-1 selected-button" color={mapType===3?"danger":"secondary"} onClick={()=>setMapType(3)}>
                            Female
                          </Button>
                          <Button className="px-5 mr-1 mb-1 selected-button" color={mapType===4?"danger":"secondary"} disabled>
                            Staff
                          </Button>
                        </Col>
                      </Row>
                    }

                    {filter.store !== null && showMap && mapType === 1 &&
                      // <Heatmap dye={dye} map={base} heatmapData={heatmap}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                      <Heatmap dye={filter.floor? filter.floor.dye_image : ""} map={filter.floor? filter.floor.image : ""} heatmapData={heatmap}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                    }

                    {filter.store !== null && showMap && mapType === 2 &&
                      // <Heatmap dye={dye} map={base} heatmapData={heatmapMale}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                      <Heatmap dye={filter.floor? filter.floor.dye_image : ""} map={filter.floor? filter.floor.image : ""} heatmapData={heatmapMale}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                    }

                    {filter.store !== null && showMap && mapType === 3 &&
                      // <Heatmap dye={dye} map={base} heatmapData={heatmapFemale}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                      <Heatmap dye={filter.floor? filter.floor.dye_image : ""} map={filter.floor? filter.floor.image : ""} heatmapData={heatmapFemale}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                    }

                    {filter.store !== null && showMap && mapType === 4 &&
                      // <Heatmap dye={dye} map={base} heatmapData={heatmapStaff}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                      <Heatmap dye={filter.floor? filter.floor.dye_image : ""} map={filter.floor? filter.floor.image : ""} heatmapData={heatmapStaff}  sections={filter.floor?filter.floor.sections: []} key={"my_map"}/>
                    }
                  </CardBody>
              </Card>
          </Col>
      </Row>

      {/* <Row className="mb-3">
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Customer Heatmap</h5>
                    </CardTitle>
                      <img src={total} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
      </Row>

      <Row className="mb-3">
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Staff Heatmap</h5>
                    </CardTitle>
                      <img src={male} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
      </Row>

      <Row className="mb-3">
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Heatmap For Males</h5>
                    </CardTitle>
                      <img src={male} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Heatmap For Females</h5>
                    </CardTitle>
                      <img src={female} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
      </Row>

      <Row className="mb-3">
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Heatmap For Kids</h5>
                    </CardTitle>
                      <img src={female} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
          <Col>
              <Card>
                  <CardBody>
                    <CardTitle>
                      <h5 className="my-0">Heatmap For Adults</h5>
                    </CardTitle>
                      <img src={male} style={{width: "100%"}} />
                  </CardBody>
              </Card>
          </Col>
      </Row> */}

      {false && <>
        <PageHeader
          title="Heatmap is not yet activated"
          description="
          Heatmap gives you a quick summary of where your customers are spending time in your store and what path they take to reach a point."
          className="mb-3"
        >
        </PageHeader>
        <Card>
          <FalconCardHeader title="Sample Heatmap" />
          <CardBody>
            <Row>
              <Col className="h-100 valign-middle">
                <h5 className="font-italic">"A picture is worth a thousand words"</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={heatmapImage} className="rounded w-100" alt=""/>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>}
    </Fragment>
  );
}

export default Heatmaps;

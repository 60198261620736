import React from 'react';
import CardWithValue from '../../components/production/CardWithValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeColors } from '../../helpers/utils';

const CategoryCard = ({title, value, icon}) => {
    return(
        <CardWithValue rate="" title={title} color="success" linkText="" icon={icon}>
            <span style={{color: themeColors.dark}}>{value}</span><br/>
        </CardWithValue>
    )
}
export default CategoryCard;
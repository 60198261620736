import React, { Component } from 'react';
import { Row, Col, Card, ButtonGroup, Button } from 'reactstrap';
import { themeColors, graphColors, grays } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const tabStyle = (type, selected) => { 
    return {
        fontSize: "16px", 
        border: "0px", 
        color: selected === type?themeColors.dark: "inherit", 
        background: "white", 
        padding:"20px 20px 17px 20px", 
        borderBottom : selected === type?"3px solid "+ graphColors.primary:"3px solid transparent"
    }
}

class HeaderFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
            iconStyle: {
                height: "100%", 
                width: "62px", 
                fontSize: "20px", 
                background: graphColors.secondary, 
                color: themeColors.light, 
                padding: "10px 20px", 
                position: "absolute", 
                left: "0px", 
                top: "0px", 
                textAlign: "center", 
                borderRadius: "12px"
            }
        }
    }

    render(){
        return (
            <Card className="mb-3" style={{borderRadius: "13px", overFlow: "hidden"}}>
              <Row noGutters>
                <Col>
                  <div style={{fontSize: "24px", background:grays["200"], color: themeColors.dark, height: "100%", position:"absolute", padding: "0px 40px", borderRadius: "12px", display: "flex"}}>
                    <div style={this.state.iconStyle}>
                      <FontAwesomeIcon icon={this.props.icon} style={{width: "20px", height: "20px", marginTop: "10px"}}/>
                    </div>
                    <span style={{fontSize: "16px", margin: "18px 20px 18px 40px"}}>{this.props.title}</span><span style={{paddingTop: "12px"}}>{this.props.value}</span>
                  </div>
                </Col>
                <Col className="text-right h-100 align-middle">
                  <ButtonGroup size="sm" style={{borderRadius: "0px"}}>
                    <Button style={tabStyle("today", this.props.selected)} onClick={() => this.props.setSelected("today")}>
                      Today
                    </Button>
                    <Button style={tabStyle("yesterday", this.props.selected)} onClick={() => this.props.setSelected("yesterday")}>
                      Yesterday
                    </Button>
                    <Button style={tabStyle("this_week", this.props.selected)} onClick={() => this.props.setSelected("this_week")}>
                      7 days
                    </Button>
                    <Button style={tabStyle("this_month", this.props.selected)} onClick={() => this.props.setSelected("this_month")}>
                      30 days
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
          </Card>
        )
    }
}

export default HeaderFilter;
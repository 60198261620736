import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import userImage from '../../assets/img/user.jpeg';
import Avatar from '../common/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const user = useSelector((state)=> state.auth.user);
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logout())
  }
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
      className="mx-1"
    >
      <DropdownToggle nav className="pr-0">
        <div style={{display: "flex", textAlign: "center", justifyContent: "center", background: "white", padding: "4px", borderRadius: "20px"}}>
          <Avatar src={user? user.image.length?user.image : userImage : userImage} />
          <span style={{marginTop: "6px", marginLeft: "8px"}}>Account</span> 
          <span style={{marginTop: "6px", marginLeft: "8px", marginRight: "8px"}}><FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></span> 
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/pages/profile">
            Profile &amp; account
          </DropdownItem>
          <DropdownItem onClick={()=>logoutUser()} tag={Link} to="/auth/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;

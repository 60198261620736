import React, { useContext } from 'react';
import { Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
import CartNotification from './CartNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import classNames from 'classnames';
import { navbarBreakPoint } from '../../config';
import { useSelector, useDispatch } from 'react-redux';

const TopNavRightSideNavItem = () => {
  var user = useSelector((state)=> state.auth.user);
  const { isTopNav, isCombo } = useContext(AppContext);
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {/* <NavItem className="mx-1">
        <SettingsAnimatedIcon/>
      </NavItem>
      {(isCombo || isTopNav) && (
        <NavItem className={classNames(`p-2 px-lg-0 cursor-pointer`, { [`d-${navbarBreakPoint}-none`]: isCombo })}>
          <NavLink tag={Link} to="/changelog" id="changelog">
            <FontAwesomeIcon icon="code-branch" transform="right-6 grow-4" />
          </NavLink>
          <UncontrolledTooltip autohide={false} placement="left" target="changelog">
            Changelog
          </UncontrolledTooltip>
        </NavItem>
      )} */}
      {/* <CartNotification /> */}
      {/* <NotificationDropdown className="mx-1"/> */}
      <NavItem className="mx-1">
        <img src={user.client_id[0].logo} style={
          {
            width: "auto",
            height: "40px",
            // borderRadius: "50%",
            // border: "4px solid white"
          }
        }/>
      </NavItem>
      <ProfileDropdown className="mx-1"/>
    </Nav>
  );
};

export default TopNavRightSideNavItem;

import React, { Component } from 'react';
import { Row, Col, Card, ButtonGroup, Button } from 'reactstrap';
import { themeColors, graphColors, grays } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

const tabStyle = (type, selected) => { 
    return {
        fontSize: "16px", 
        border: "0px", 
        color: selected === type?themeColors.dark: "inherit", 
        background: "white", 
        padding:"20px 20px 17px 20px", 
        borderBottom : selected === type?"3px solid "+ graphColors.primary:"3px solid transparent"
    }
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected?graphColors.primary:'',
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "0px",
    }),
    control: (provided) => ({
        ...provided,
        height: "40px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "8px",
        border: "1px solid transparent"
    })
}

const iconStyle = {
    height: "100%", 
    width: "62px", 
    fontSize: "20px", 
    background: graphColors.secondary, 
    color: themeColors.light, 
    padding: "10px 20px", 
    position: "absolute", 
    left: "0px", 
    top: "0px", 
    textAlign: "center", 
    borderRadius: "12px"
}

class CompareHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <Card className="mb-3" style={{borderRadius: "13px", overFlow: "hidden"}}>
              <Row noGutters>
                <Col>
                  <div style={{fontSize: "24px", background:grays["200"], color: themeColors.dark, height: "100%", position:"absolute", padding: "0px 40px", borderRadius: "12px", display: "flex"}}>
                    <div style={iconStyle}>
                      <FontAwesomeIcon icon={this.props.icon} style={{width: "20px", height: "20px", marginTop: "10px"}}/>
                    </div>
                    <span style={{fontSize: "16px", margin: "18px 20px 18px 40px"}}>{this.props.title}</span>
                  </div>
                </Col>
                <Col className="text-right h-100 align-middle" style={{height: "64px"}}>
                    <ButtonGroup size="sm" style={{borderRadius: "0px"}}>
                        <Button style={tabStyle("single", this.props.compare.value)} onClick={() => this.props.setCompareType(this.props.compareTypes[0])}>
                            Single
                        </Button>
                        <Button style={tabStyle("multiple", this.props.compare.value)} onClick={() => this.props.setCompareType(this.props.compareTypes[1])}>
                            Multiple
                        </Button>
                    </ButtonGroup>
                </Col>
              </Row>
          </Card>
        )
    }
}

export default CompareHeader;
import React, { useEffect, useContext, createContext, useState  } from 'react';
import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));

const Layout = () => {
  useEffect(() => {
    AuthCardRoutes.preload();
  }, []);

  return (
    <ProvideAuth>
      <Router fallback={<span />}>
        <Switch>
          <Route path="/auth" component={AuthCardRoutes} />
          <Route path="/errors" component={ErrorLayout} />
          <PrivateRoute path="/">
            <Route component={DashboardLayout} />
          </PrivateRoute>
        </Switch>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
      </Router>
    </ProvideAuth>
  );
};

export default Layout;


const auth = {
  isAuthenticated: false,
  signin(cb) {
    auth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    auth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  var rawUser = useSelector((state)=> state.auth.user);
  //var rawUser = JSON.parse(localStorage.getItem("user"));
  if(rawUser == null || rawUser == undefined){
    rawUser = null;
  }
  const [user, setUser] = useState(rawUser);

  const signin = cb => {
    return auth.signin(() => {
      setUser(user);
      cb();
    });
  };

  const signout = cb => {
    return auth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  var rawUser = useSelector((state)=> state.auth.user);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rawUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
import { actionTypes } from "../constants/action-types";
import { graphColors } from '../../helpers/utils';
import moment from 'moment';

const tempSeries = (series = [], color=graphColors.primary, title="", type="bar", smooth = false) => {
    return{
        title: "Title_"+title,
        label: "Label_"+title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
    }
}

const lineSeries = (series = [], color=graphColors.primary, title="", type="line", smooth = false) => {
    return{
        title: "Title_"+title,
        label: "Label_"+title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
    }
}

const getLineLabels = (startDate, endDate) => {
    var labels = [];
    let start = moment(startDate);
    let end = moment(endDate);
    if(end.diff(start, "day") <= 1){
        while(end.diff(start, "hour") > 0){
            labels.push(start.format("hh:mm a"));
            start = start.add(1, "hour");
        }
    }else{
        while(end.diff(start, "day") >= 0){
            labels.push(start.format("MMM D"));
            start = start.add(1, "day");
        }
    }
    return labels;
}

const compileData = (data) => {
    var temp = {prospects:[], visitors: []};
    var arr = ["front", "back", "left", "right"];
    arr.forEach((item)=>{
        var prosObject = null;
        data.prospects.forEach((row)=>{
            if(row._id.direction === item){
                prosObject = row;
            }
        });
        var visitObject = null;
        data.visitors.forEach((row)=>{
            if(row._id.direction === item){
                visitObject = row;
            }
        });
        temp.prospects.push(convertToformat(item, prosObject));
        temp.visitors.push(convertToformat(item, visitObject));
    });
    return temp;
}

const compileLineData = (startDate, endDate, rawdata, entity, title) => {
    var data = [];
    var index = 0;
    let start = moment(startDate);
    let end = moment(endDate);
    if(end.diff(start, "day") <= 1){
        rawdata = rawdata.sort((a,b)=>{return a._id-b._id});
        while(end.diff(start, "hour") > 0){
            if(rawdata.length && index < rawdata.length){
                if(start.format("HH") === rawdata[index]._id){
                    data.push(Math.round(rawdata[index][entity])%120);
                    index++;
                }else{
                    data.push(0);
                }
            }else{
                data.push(0);
            }
            start = start.add(1, "hour");
        }
    }else{
        rawdata = rawdata.sort((a,b)=>{return new Date(a._id)-new Date(b._id)});
        while(end.diff(start, "day") >= 0){
            if(rawdata.length && index < rawdata.length){
                if(start.format("YYYY-MM-DD") === rawdata[index]._id){
                    data.push(Math.round(rawdata[index][entity])%120);
                    index++;
                }else{
                    data.push(0);
                }
            }else{
                data.push(0);
            }
            start = start.add(1, "day");
        }
    }
    return lineSeries(data, ["avg_dt"].indexOf(entity) >= 0 ? graphColors.primary : graphColors.secondary, title);
}

const directions = {
    "front": "Front",
    "back": "Back",
    "left": "Left",
    "right": "Right"
}
const getDataTemplate = (dir) => {
    return {label: directions[dir], total: 0, male: 0, female: 0, adult: 0, kid: 0, dwelltime: 0}
}

const convertToformat = (dir, obj=null) => {
    var template = getDataTemplate(dir);
    if(obj){
        template.dwelltime = obj.avg_dt;
        template.total = obj.footfall;
        template.male = obj.male;
        template.female = obj.female;
        template.adult = obj.adult;
        template.kid = obj.kid;
    }
    return template;
}

const initialState = {
    labels: getLineLabels(new Date(), new Date()),
    data: compileData({prospects: [], visitors: []}),
    graph: null,
    filter: {
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        section: null,
        sections: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day")
    },
    loader: false
}

export const vehicleReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_VEHICLE:
            var data = compileData(payload);
            return { ...state, data: data };
        case actionTypes.SET_VEHICLE_GRAPH:
            var temp = {
                labels: getLineLabels(state.filter.start, state.filter.end),
                graph: compileLineData(state.filter.start, state.filter.end, payload, "avg_dt", "Average Dwell Time (s)")
            }
            return { ...state, ...temp};
        case actionTypes.SET_VEHICLE_FILTER:
                return { ...state, filter: payload };
        case actionTypes.SET_VEHICLE_LOADER:
                return { ...state, loader: payload };
        default:
            return state;
    }
}
import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Progress, Nav, NavItem, NavLink, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { footfallRequest, footfallGraphRequest, categoryRequest } from '../helpers/requests';
import moment from 'moment';
import HeaderFilter from './template/HeaderFilter';
import FormFilter from './template/FormFilter';
import { graphColors, themeColors } from '../helpers/utils';
import './css/WalkRoute.css';
import CardHeader from 'reactstrap/lib/CardHeader';
import { setCategoryFilter, setCategoryLoader } from '../redux/actions/categoryActions';
const downloadOptions = [
  {value: "excel", label: "Excel"},
  {value: "pdf", label: "PDF"}
];

// const raoData = [
//     {
//         total: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         male: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         female: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         adult: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         kid: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]}
//     },
//     {
//         total: {top: [150, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         male: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         female: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         adult: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         kid: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]}
//     },
//     {
//         total: {top: [160, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         male: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         female: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         adult: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         kid: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]}
//     },
//     {
//         total: {top: [170, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         male: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         female: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         adult: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]},
//         kid: {top: [145, 167], left: [70, 90], right: [90, 111], bottom: [50, 80]}
//     }
// ]

// const rawData = {
//   total: [
//     {total: 10620, top: 30, right: 65, bottom: 0, left: 5},
//     {total: 17512, top: 20, right: 10, bottom: 70, left: 0},
//     {total: 13421, top: 70, right: 0, bottom: 0, left: 30},
//     {total: 12127, top: 30, right: 25, bottom: 45, left: 0},
//   ],
//   male:[
//     {total: 70358, top: 68, right: 22, bottom: 0, left: 10},
//     {total: 13107, top: 25, right: 60, bottom: 15, left: 0},
//     {total: 93526, top: 37, right: 0, bottom: 0, left: 63},
//     {total: 81271, top: 42, right: 19, bottom: 39, left: 0},
//   ],
//   female:[
//     {total: 3214, top: 21, right: 74, bottom: 0, left: 5},
//     {total: 4123, top: 20, right: 20, bottom: 60, left: 0},
//     {total: 3998, top: 70, right: 0, bottom: 0, left: 30},
//     {total: 4009, top: 43, right: 15, bottom: 42, left: 0},
//   ],
//   adult:[
//     {total: 9554, top: 40, right: 55, bottom: 0, left: 5},
//     {total: 16115, top: 17, right: 13, bottom: 70, left: 0},
//     {total: 12779, top: 49, right: 0, bottom: 0, left: 51},
//     {total: 11435, top: 43, right: 23, bottom: 34, left: 0},
//   ],
//   kid:[
//     {total: 977, top: 31, right: 42, bottom: 0, left: 27},
//     {total: 1566, top: 25, right: 13, bottom: 62, left: 0},
//     {total: 842, top: 45, right: 0, bottom: 0, left: 55},
//     {total: 711, top: 30, right: 43, bottom: 27, left: 0},
//   ],
// }

const bubble = (top, left) => {
    return {
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        radius: "50%",
        mozRadius: "50%",
        border: "4px solid "+themeColors.danger,
        position: "absolute",
        left: left + "px",
        top: top + 'px',
        texAlight: "center",
        lineHeight: "70px",
        background: "white",
        cursor: "pointer",
        boxShadow: "0px 0px 6px #B2B2B2"
    }
}

const WalkRoute = () => {
  const dispatch = useDispatch();
  var rawData = useSelector((state)=> state.category.data);
  var visitorsGraph = useSelector((state)=> state.category.graphs);
  var filter = useSelector((state)=> state.category.filter);
  var loader = useSelector((state)=> state.category.loader);
  const user = useSelector((state)=> state.auth.user);
  const [showMap, setShowMap] = useState(true);
  const [isPercentage, setIsPercentage] = useState(false);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  var floors = [];
  if(stores.length){
    floors = stores[0].floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
  }

  var carSections = [];
  if(floors.length){
    carSections = floors[0].sections.map((val, index)=>{
      return {...val, value: val._id};
    });
  }

  const [data, setData] = useState(rawData.total);
  const [dataIndex, setDataIndex] = useState(0);
  const setCurrentData = (index) => {
      setDataIndex(index);
      setData(rawData[index]);
  }

  const [activeTab, setActiveTab] = useState('0');
  
  const toggle = tab => {
    if (activeTab !== tab){
      setActiveTab(tab);
      tab = parseInt(tab);
      if(tab === 1){
        setData(rawData.total);
      }else if(tab === 2){
        setData(rawData.male);
      }else if(tab === 3){
        setData(rawData.female);
      }else if(tab === 4){
        setData(rawData.adult);
      }else if(tab === 5){
        setData(rawData.kid);
      }
    }
  };

  const [isSelected, setIsSelected] = useState(filter.type);
  const setSelected = (selection) =>{
    setIsSelected(selection);
    var startDate;
    var endDate;
    try{
      if(selection == "today"){
          startDate = moment(new Date()).startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "yesterday"){
          startDate = moment(new Date()).subtract(1, "day").startOf("day");
          endDate = moment(new Date()).subtract(1, "day").endOf("day");
      }else if(selection == "this_week"){
          startDate = moment(new Date()).subtract(6, "day").startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection == "this_month"){
        startDate = moment(new Date()).subtract(30, "day").startOf("day");
        endDate = moment(new Date()).endOf("day");
      }
      dispatch(setCategoryFilter({...filter, start: startDate, end: endDate, type: selection}));
      var requestData = {
        params: {
          fid: filter.floor? filter.floor._id:filter.store.floors[0]._id,
          sid: filter.store? filter.store.value: stores[0].value, 
          start: startDate, 
          end: endDate
        }, 
        dispatch: dispatch
      }
      dispatch(setCategoryLoader(true));
      // footfallRequest(requestData);
      // footfallGraphRequest(requestData);
      getFootfall(requestData);
    }catch(e){
    }
  }
  const setStartDate = (startDate) =>{
    dispatch(setCategoryFilter({...filter, start: startDate, type: null}));
  }
  const setEndDate = (endDate) =>{
    dispatch(setCategoryFilter({...filter, end: endDate, type: null}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    var sections = [];
    tempFloors[0].sections.forEach((val)=>{
      if(val.type==="routes"){
        sections.push(val);
      }
    });
    dispatch(setCategoryFilter({...filter, store: store, stores: stores, floor: tempFloors[0], floors: tempFloors, sections: sections, type: null}));
  }
  const setFloor = (floor) =>{
    var sections = [];
    floor.sections.forEach((val)=>{
      if(val.type==="routes"){
        sections.push(val);
      }
    });
    dispatch(setCategoryFilter({...filter, floor: floor, sections: sections, type: null}));
  }
  const setDownloadOption = (option) => {
    dispatch(setCategoryFilter({...filter, downloadOption: option}));
  }

  const getFootfall = (_requestData = null) => {
    setActiveTab('0');
    var requestData = _requestData?_requestData:{
      params: {
        fid: filter.floor? filter.floor._id:stores[0].floors[0]._id,
        sid: filter.store? filter.store.value: stores[0].value,
        // sec_id: "61168903d14ee62727e1b658", 
        start: filter.start, 
        end: filter.end
      }, 
      dispatch: dispatch
    }
    dispatch(setCategoryLoader(true));
    categoryRequest(requestData);
  }

  useEffect(()=>{
    setTimeout(()=>{
      toggle('1');
    }, 1000);
  }, [rawData]);

  useEffect(()=>{
    if(loader == false){
      setTimeout(()=>{
        toggle('1');
      }, 1000);
    }
  }, [loader]);

  const [popoverOpen, setPopoverOpen] = useState(new Array(carSections.length).fill(false));
  const toggleMe = (index, status=-1) => {
    var _popoverOpen = popoverOpen.slice();
    if(status == -1){
        _popoverOpen[index] = !_popoverOpen[index];
        setPopoverOpen(_popoverOpen);
    }else{
        _popoverOpen[index] = status > 0 ? true : false ;
        setPopoverOpen(_popoverOpen);
    }
}
  const [imageData, setImageData] = useState({width: 0, height: 0});
  useEffect(()=>{
    if(filter && filter.floor && filter.floor.image){
      let img = new Image();
      img.onload = () => {
        setImageData({width: img.width, height: img.height})
      };
      img.src= filter.floor.image;
    }
  },[filter])

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getFootfall();
    }, 200);
  }, []);

  return(
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Customer Journey</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <HeaderFilter icon="star" title="Total Stores" value={user? user.access.length<10? "0"+user.access.length : user.access.length  : "00"} selected={filter.type} setSelected={(val) => setSelected(val)}/>
        </Col>
      </Row>
      <FormFilter filter={filter} setStore={(e)=>setStore(e)} setFloor={(e)=>setFloor(e)} setStartDate={(e)=>setStartDate(e)} setEndDate={(e)=>setEndDate(e)} submit={()=>getFootfall()}/>
      <Row>
        <Col style={{height: "20px"}}>
          {
            loader &&
            <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
          }
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="text-left">
          <Button color={isPercentage === false ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setIsPercentage(false)}>Show Values</Button>
          <Button color={isPercentage === true ? "danger" : "secondary"} className="btn-sm" onClick={()=>setIsPercentage(true)}>Show Percentage</Button> 
        </Col>
      </Row>

      <Row className="mb-3" noGutters>
          <Col>
              <Card>
                  <CardHeader>
                    <Nav tabs>
                      <NavItem className='cursor-pointer'>
                        <NavLink
                          className={activeTab == 1 ? 'active px-5': 'px-5'}
                          onClick={() => {
                            toggle('1');
                            
                          }}
                        >
                          <strong>Total</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem className='cursor-pointer'>
                        <NavLink
                          className={activeTab == 2 ? 'active px-5': 'px-5'}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          <strong>Males</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem className='cursor-pointer'>
                        <NavLink
                          className={activeTab == 3 ? 'active px-5': 'px-5'}
                          onClick={() => {
                            toggle('3');
                          }}
                        >
                          <strong>Females</strong>
                        </NavLink>
                      </NavItem>
                      {/* <NavItem className='cursor-pointer'>
                        <NavLink
                          className={activeTab == 4 ? 'active px-5': 'px-5'}
                          onClick={() => {
                            toggle('4');
                          }}
                        >
                          <strong>Adults</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem className='cursor-pointer'>
                        <NavLink
                          className={activeTab == 5 ? 'active px-5': 'px-5'}
                          onClick={() => {
                            toggle('5');
                          }}
                        >
                          <strong>Kids</strong>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <div>
                      
                      {filter.store && showMap &&
                        <>
                          {
                            imageData.width && imageData.height &&
                            <svg height={imageData.height} width={imageData.width} style={{position:"absolute", zIndex: 2}}>
                              {
                                carSections.filter(a=>a.type=="item").map((section, ind) => {
                                    var points = "";
                                    var xCords = [];
                                    var yCords = [];
                                    section.positions.map((point, index)=>{
                                        if(index>0){
                                            points += " "+point.x+","+point.y;
                                        }else{
                                            points += point.x+","+point.y;
                                        }
                                        xCords.push(point.x);
                                        yCords.push(point.y);
                                        return null;
                                    });
                                    var text = {
                                        x: Math.floor(Math.min(...xCords) + (Math.max(...xCords) - Math.min(...xCords))/2), 
                                        y: Math.floor(Math.min(...yCords) + (Math.max(...yCords) - Math.min(...yCords))/2)
                                    };
                                    return(
                                        <Fragment key={"section_"+ind}>
                                            <text x={text.x} y={text.y} textAnchor="middle" fill="#0000008a" fontSize="16" fontWeight="900">{ind+1}</text>
                                            <polygon 
                                                onClick={() => console.log(section)} 
                                                style={{fill: 'rgba(0,0,0,0.1)'}} 
                                                className="it"
                                                points={points}
                                                onMouseEnter={()=>toggleMe(ind, 1)}
                                                onMouseLeave={()=>toggleMe(ind, 0)}
                                                id={"Popover-" + section._id}
                                            />
                                            <Popover
                                                placement="top"
                                                isOpen={popoverOpen[ind]}
                                                target={"Popover-" + section._id}
                                                toggle={()=>toggleMe(ind)}
                                                key={"Popover-" + section._id}
                                            >
                                                <PopoverHeader>{section.label}</PopoverHeader>
                                                <PopoverBody>
                                                    Location: {section.slot}
                                                </PopoverBody>
                                            </Popover>
                                        </Fragment>
                                    )
                                })
                              }
                            </svg>
                          }
                          <img src={filter.floor? filter.floor.dye_image : ""} alt="" style={{position: "absolute"}}/>
                          <img src={filter.floor? filter.floor.image : ""} alt=""/>
                        </>
                      }
                      {
                        filter.sections.map((section, index)=>{
                          return data[index] && <CrossShape isPercentage={isPercentage} top={section.positions[0].y+80} left={section.positions[0].x+12} x="6" y="40" directional={data[index]} key={"cross_"+index}/>
                        })
                      }
                    </div>
                  </CardBody>
              </Card>
          </Col>
      </Row>
      
    </Fragment>
  )
}


const CrossShape = (props) => {
  const isActive = (dir) => {
    if(dir == 'top'){
      return (props.directional.top>props.directional.left && props.directional.top > props.directional.right && props.directional.top > props.directional.bottom) ? "active":'';
    }else if(dir == 'left'){
      return (props.directional.left>props.directional.top && props.directional.left > props.directional.right && props.directional.left > props.directional.bottom) ? "active":'';
    }else if(dir == 'right'){
      return (props.directional.right>props.directional.left && props.directional.right > props.directional.top && props.directional.right > props.directional.bottom) ? "active":'';
    }else if(dir == 'bottom'){
      return (props.directional.bottom>props.directional.left && props.directional.bottom > props.directional.right && props.directional.bottom > props.directional.top) ? "active":'';
    }
    return '';
  }

  if(props.isPercentage){
    return (
      <Fragment>
        <div>
          <div className={"arrows arrow-up " + isActive('top')} style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.y+"px", position: "absolute", background: "#d51c29", marginTop:-1*props.y+"px"}}><div>{parseInt(100*props.directional.top/(props.directional.footfall?props.directional.footfall:1) )}%</div></div>
          <div className={"arrows arrow-right " + isActive('right')} style={{left: props.left+"px", top: props.top+"px", width:props.y+"px", height: props.x+"px", position: "absolute", background: "#d51c29", marginLeft:props.x+"px"}}><div>{parseInt(100*props.directional.right/(props.directional.footfall?props.directional.footfall:1))}%</div></div>
          <div className="central" style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.x+"px", position: "absolute", background: "#d51c29"}}><div></div></div>
          <div className={"arrows arrow-down " + isActive('bottom')} style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.y+"px", position: "absolute", background: "#d51c29", marginTop:props.x+"px"}}><div>{parseInt(100*props.directional.bottom/(props.directional.footfall?props.directional.footfall:1))}%</div></div>
          <div className={"arrows arrow-left " + isActive('left')} style={{left: props.left+"px", top: props.top+"px", width:props.y+"px", height: props.x+"px", position: "absolute", background: "#d51c29", marginLeft:-1*props.y+"px"}}><div>{parseInt(100*props.directional.left/(props.directional.footfall?props.directional.footfall:1))}%</div></div>
        </div>
      </Fragment>
    )
  }else{
    return (
      <Fragment>
        <div>
          <div className={"arrows arrow-up " + isActive('top')} style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.y+"px", position: "absolute", background: "#d51c29", marginTop:-1*props.y+"px"}}><div>{props.directional.top}</div></div>
          <div className={"arrows arrow-right " + isActive('right')} style={{left: props.left+"px", top: props.top+"px", width:props.y+"px", height: props.x+"px", position: "absolute", background: "#d51c29", marginLeft:props.x+"px"}}><div>{props.directional.right}</div></div>
          <div className="central" style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.x+"px", position: "absolute", background: "#d51c29"}}><div></div></div>
          <div className={"arrows arrow-down " + isActive('bottom')} style={{left: props.left+"px", top: props.top+"px", width:props.x+"px", height: props.y+"px", position: "absolute", background: "#d51c29", marginTop:props.x+"px"}}><div>{props.directional.bottom}</div></div>
          <div className={"arrows arrow-left " + isActive('left')} style={{left: props.left+"px", top: props.top+"px", width:props.y+"px", height: props.x+"px", position: "absolute", background: "#d51c29", marginLeft:-1*props.y+"px"}}><div>{props.directional.left}</div></div>
        </div>
      </Fragment>
    )
  }
}

export default WalkRoute;

import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Progress, ButtonGroup, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { insightRequest } from '../helpers/requests';
import { setInsightData, setInsightFilter, setInsightProgress } from '../redux/actions/insightActions';
import moment from 'moment';
import './css/Insights.css';
import { themeColors, graphColors, grays } from '../helpers/utils';
import CardWithValue from '../components/production/CardWithValue';
import ProgressInsight from './template/ProgressInsight';
import BarComparison from './template/BarComparison';
import PieComparison from './template/PieComparison';
import InsightFilter from './template/InsightFilter';
import PeakHours from './template/PeakHours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CenteralTendencyExplain from './template/CentralTendencyExplain';
const months = [
  {value: 1, label: "January", short: "Jan"},
  {value: 2, label: "Februaru", short: "Feb"},
  {value: 3, label: "March", short: "Mar"},
  {value: 4, label: "April", short: "Apr"},
  {value: 5, label: "May", short: "May"},
  {value: 6, label: "June", short: "Jun"},
  {value: 7, label: "July", short: "Jul"},
  {value: 8, label: "August", short: "Aug"},
  {value: 9, label: "September", short: "Sep"},
  {value: 10, label: "October", short: "Oct"},
  {value: 11, label: "November", short: "Nov"},
  {value: 12, label: "December", short: "Dec"}
];

const tabStyle = (type, selected) => { 
  return {
      fontSize: "16px", 
      border: "0px", 
      color: selected === type?themeColors.dark: "inherit", 
      background: "white", 
      padding:"20px 20px 17px 20px", 
      borderBottom : selected === type?"3px solid "+ graphColors.primary:"3px solid transparent"
  }
}

const iconStyle = {
  height: "100%", 
  width: "62px", 
  fontSize: "20px", 
  background: graphColors.secondary, 
  color: themeColors.light, 
  padding: "10px 20px", 
  position: "absolute", 
  left: "0px", 
  top: "0px", 
  textAlign: "center", 
  borderRadius: "12px"
}

const Insight = () => {
  const dispatch = useDispatch();
  var insights = useSelector((state)=> state.insight.data);
  var raw = useSelector(state => state.insight.insights)
  var filter = useSelector((state)=> state.insight.filter);
  var previous = useSelector((state)=> state.insight.previous);
  var loader = useSelector((state)=> state.insight.loading);
  const user = useSelector((state)=> state.auth.user);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  const [selected, setSelected] = useState(0);
  const setType = (type) =>{
    dispatch(setInsightFilter({...filter, type: type}));
  }
  const setStartDate = (date) =>{
    dispatch(setInsightFilter({...filter, date: date}));
  }
  const setWeek = (week) =>{
    dispatch(setInsightFilter({...filter, week: week}));
  }
  const setMonth = (month) =>{
    dispatch(setInsightFilter({...filter, month: month}));
  }
  const setYear = (year) =>{
    dispatch(setInsightFilter({...filter, year: year}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    dispatch(setInsightFilter({...filter, store: store, stores: stores, floors: tempFloors, floor: tempFloors[0]}));
  }

  const setFloor = (floor) =>{
    dispatch(setInsightFilter({...filter, floor: floor}));
  }

  const getInsight = () => {
    setActiveDetails("peak_hours");
    var startDate;
    var endDate;
    try{
      if(filter.type.value === "daily"){
          startDate = moment(new Date(filter.date)).startOf("day");
          endDate = moment(new Date(filter.date)).endOf("day");
      }else if(filter.type.value === "weekly"){
          startDate = moment(filter.year.value, "YYYY").week(filter.week.value).startOf("week").startOf("day");
          endDate = moment(filter.year.value, "YYYY").week(filter.week.value).endOf("week").endOf("day");
      }else if(filter.type.value === "monthly"){
          startDate = moment(filter.year.value+'-'+filter.month.value, "YYYY-MM").startOf("month").startOf("day");
          endDate = moment(filter.year.value+'-'+filter.month.value, "YYYY-MM").endOf("month").endOf("day");
      }else if(filter.type.value === "yearly"){
          startDate = moment(filter.year.value, "YYYY").startOf("year").startOf("day");
          endDate = moment(filter.year.value, "YYYY").endOf("year").endOf("day");
      }
      setSelected(0);
      var requestData = {
        params: {sid: filter.store ? filter.store._id : stores[0]._id, start: startDate, end: endDate, type: filter.type.value},
        dispatch: dispatch
      };
      dispatch(setInsightProgress(true));
      insightRequest(requestData);
    }catch(e){
    }
  }

  const toTime = (time) => {
    time = parseInt(time);
    return time<=12? ("0"+time).slice(-2) + (time===12? ":00 PM":":00 AM") : ("0"+(time-12)).slice(-2) + (time===24? ":00 AM":":00 PM");
  }
  
  const fromTime = (time) => {
    time = parseInt(time)-1;
    return time<=12? time + (time===12? ":00 PM":":00 AM") : ("0"+(time-12)).slice(-2) + (time===24? ":00 AM":":00 PM");
  }

  // const getLead = (first, second) =>{
  //   if(first <= second){
  //     return {label: first.label, value: first.value, percent: Math.floor(first.value*100/(first.value+second.value))}
  //   }else{
  //     return {label: second.label, value: second.value, percent: Math.floor(second.value*100/(first.value+second.value))}
  //   }
  // }

  const priority = ["First", "Second", "Third"];
  const colors = ["#e16b05", "#f1bc1f", "#a2b86c", "#6f6f6f"];
  const typeWord = {"daily": "day", "weekly": "week", "monthly": "month", "yearly": "year"};
  const typeDuration = {"daily": "hours", "weekly": "days", "monthly": "days", "yearly": "months"};
  const getDuration = (value, currentCase=null) => {
    switch(currentCase?currentCase:previous.type.value){
      case "daily":
        return fromTime(value) + " - " + toTime(value);
      case "days":
        if(typeof value === "string"){
          return fromTime(value) + " - " + toTime(value);
        }else{
          var arr = moment(value.date).format("llll").split(",");
          arr = [arr[0], arr[1]].join(",");
          return arr+" | "+fromTime(value.hour) + " - " + toTime(value.hour);
        }
      case "weekly":
        var arr = moment(value).format("llll").split(",");
        arr = [arr[0], arr[1]].join(",");
        return arr;
      case "monthly":
        //var arr = moment(previous.year.value+"/"+previous.month.value+"/"+value).format("llll").split(",");
        //var arr = moment(value).format("llll").split(",");
        //arr = [arr[0], arr[1]].join(",");
        var start = moment().year(filter.year.value).week(value).startOf("week").format('llll').split(",");
        var end = moment().year(filter.year.value).week(value).endOf("week").format('llll').split(",");
        var arr = [start[0], start[1]].join(",") + " - " + [end[0], end[1]].join(",");
        return arr;
      case "yearly":
        var value = parseInt(value);
        if(value === NaN || value === "NaN"){
          return "";
        }
        var val = "";
        try{
          val = months[value].short+", "+filter.year.value;
        }catch(e){
          val = "";
        }
        return val;
      case "avg_peak_hours":
        return fromTime(value) + " - " + toTime(value);
      case "avg_peak_hour":
        return fromTime(value) + " - " + toTime(value);
      case "peak_hours":
        if(typeof value === "string"){
          return fromTime(value) + " - " + toTime(value);
        }else{
          var arr = moment(value.date).format("llll").split(",");
          arr = [arr[0], arr[1]].join(",");
          return arr+" | "+fromTime(value.hour) + " - " + toTime(value.hour);
        }
      case "peak_days":
        var arr = moment(value).format("llll").split(",");
        arr = [arr[0], arr[1]].join(",");
        return arr;
      case "peak_weeks":
        //var arr = moment(previous.year.value+"/"+previous.month.value+"/"+value).format("llll").split(",");
        //var arr = moment(value).format("llll").split(",");
        //arr = [arr[0], arr[1]].join(",");
        var start = moment().year(filter.year.value).week(value).startOf("week").format('llll').split(",");
        var end = moment().year(filter.year.value).week(value).endOf("week").format('llll').split(",");
        var arr = [start[0], start[1]].join(",") + " - " + [end[0], end[1]].join(",");
        return arr;
      case "peak_months":
        var value = parseInt(value);
        if(value === NaN || value === "NaN"){
          return "";
        }
        var val = "";
        try{
          val = months[value].short+", "+filter.year.value;
        }catch(e){
          val = "";
        }
        return val;
      default:
        return "";
    }
  }
  const [tendency, setTendency] = useState("mean");

  const [activeDetail, setActiveDetails] = useState("peak_hours");
  const setActiveDetail = (val, data) => {
    setSelected(0);
    setActiveDetails(val);
    dispatch(setInsightData(data));
    
  }

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getInsight();
    }, 200);
  }, []);
  
  return (
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Insights</h4>
        </Col>
      </Row>
      <InsightFilter 
        filter={filter}
        setType={(e)=>setType(e)}
        setStore={(e)=>setStore(e)}
        setFloor={(e)=>setFloor(e)}
        setStartDate={(e)=>setStartDate(e)}
        setWeek={(e)=>setWeek(e)}
        setMonth={(e)=>setMonth(e)}
        setYear={(e)=>setYear(e)}
        submit={()=>getInsight()}
      />
      
      {/* { insights.length ?
        <Row noGutters className="mb-2">
          <Col>
            <Card>
              <CardBody>
                <strong style={{color: themeColors.danger}}>Top {insights.length} {typeDuration[previous.type.value]}</strong> found for <strong style={{color: themeColors.danger}}>{previous.store.label}</strong> branch for the given <strong  style={{color: themeColors.danger}}>{typeWord[previous.type.value]}</strong>
              </CardBody>
            </Card>
          </Col>
        </Row>
        :
        <>
        </>
      } */}
      
      {/* { insights.length ?
        <Row noGutters className="mb-2">
          {
            insights.map((val, index) => {
              return(
                <Col md={4} className={index===0?"pr-1": index === 1? "px-1": "pl-1"}>
                  <ShortScoreCard insight={val} duration={getDuration(val._id)} />
                </Col>
              )
            })
          }
        </Row>
        :
        <>
        </>
      } */}

      {
        insights.length > 0 && (filter.type.value === "weekly" || filter.type.value === "monthly" || filter.type.value === "yearly") && raw.peak_hours !== undefined &&
        
        <Card className="my-4">
          <Row noGutters>
            <Col md={3}>
              <div style={{fontSize: "24px", background:grays["200"], color: themeColors.dark, height: "100%", position:"absolute", padding: "0px 40px", borderRadius: "12px", display: "flex"}}>
                <div style={iconStyle}>
                  <FontAwesomeIcon icon={"star"} style={{width: "20px", height: "20px", marginTop: "10px"}}/>
                </div>
                <span style={{fontSize: "16px", margin: "18px 20px 18px 40px"}}>{"Show Details"}</span>
              </div>
            </Col>
            <Col className="h-100 align-middle px-4 text-right" style={{height: "64px"}}>
              <ButtonGroup size="sm" style={{borderRadius: "0px"}}>
                  <Button style={tabStyle("peak_hours", activeDetail)} onClick={() => setActiveDetail("peak_hours", raw.peak_hours)}>
                      Peak Hours
                  </Button>
                  <Button style={tabStyle("avg_peak_hours", activeDetail)} onClick={() => setActiveDetail("avg_peak_hours", filter.type.value==="yearly"?raw.avg_peak_hour:raw.avg_peak_hours)}>
                      Average Peak Hours
                  </Button>
                  <Button style={tabStyle("peak_days", activeDetail)} onClick={() => setActiveDetail("peak_days", raw.top_days)}>
                      Peak Days
                  </Button>
                  {
                    (filter.type.value === "monthly" || filter.type.value === "yearly") &&
                    <Button style={tabStyle("peak_weeks", activeDetail)} onClick={() => setActiveDetail("peak_weeks", raw.peak_weeks)}>
                        Peak Weeks
                    </Button>
                  }
                  {
                    filter.type.value === "yearly" &&
                    <Button style={tabStyle("peak_months", activeDetail)} onClick={() => setActiveDetail("peak_months", raw.top_months)}>
                        Peak Months
                    </Button>
                  }
              </ButtonGroup>
            </Col>
          </Row>
        </Card>
      }

      <Row>
        <Col style={{height: "20px"}}>
        {
          loader &&
          <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
        }
       </Col>
      </Row>
      { insights.length ?
          <Row noGutters className="mb-2">
            <Col md={3} className="pr-2">
              {
                insights.map((val, index) => {
                  if(val._id){
                    return(
                      <Card key={index} color={selected === index ? "danger": "light"} onClick={()=>setSelected(index)} className="mb-2" style={{cursor: "pointer"}}>
                        <CardBody>
                          <h5 className="m-0">{getDuration(activeDetail=='peak_hours'?val._id:typeof val._id === "string"?val._id: val._id.hour, activeDetail)}</h5>
                          <span className={`badge badge-soft-danger rounded-capsule mr-2`}>{priority[index]}</span>
                        </CardBody>
                      </Card>
                    )
                  }
                })
              }
            </Col>
            <Col md={4} className="pr-2">
            {
              insights.map((val, index) => {
                if(selected === index && val._id){
                  return(
                    <ProgressInsight visitors={val.total} duration={getDuration(typeof val._id === "string"?val._id: val._id.hour, activeDetail)} key={"progress_"+index}/>
                  )
                }
              })
            }
            </Col>
            <Col>
              <div className="card-deck">
                  <CardWithValue rate="" title="Male" color="success" linkText="">
                    {insights[selected].male} <small style={{fontSize:"14px"}}>Visitors</small> <br/>
                    <small style={{fontSize:"18px"}}>
                      <span className={`badge badge-soft-danger rounded-capsule mr-2`}>
                      {insights[selected].total === 0 ? "NA" : Math.round(insights[selected].male * 100 / insights[selected].total)}%
                      </span>
                    </small>
                  </CardWithValue>
                  <CardWithValue rate="" title="Female" color="success" linkText="">
                    {insights[selected].female} <small style={{fontSize:"14px"}}>Visitors</small> <br/>
                    <small style={{fontSize:"18px"}}>
                      <span className={`badge badge-soft-danger rounded-capsule mr-2`}>
                        {insights[selected].total === 0 ? "NA" : Math.round(insights[selected].female * 100 / insights[selected].total)}%
                      </span>
                    </small>
                  </CardWithValue>
                </div>
              
                <div className="card-deck">
                  <CardWithValue rate="" title="Kids" color="success" linkText="">
                    {insights[selected].kid} <small style={{fontSize:"14px"}}>Visitors</small> <br/>
                    <small style={{fontSize:"18px"}}>
                      <span className={`badge badge-soft-danger rounded-capsule mr-2`}>
                      {insights[selected].total === 0 ? "NA" : Math.round(insights[selected].kid * 100 / insights[selected].total)}%
                      </span>
                    </small>
                  </CardWithValue>
                  <CardWithValue rate="" title="Adults" color="success" linkText="">
                    {insights[selected].adult} <small style={{fontSize:"14px"}}>Visitors</small> <br/>
                    <small style={{fontSize:"18px"}}>
                      <span className={`badge badge-soft-danger rounded-capsule mr-2`}>
                      {insights[selected].total === 0 ? "NA" : Math.round(insights[selected].adult * 100 / insights[selected].total)}%
                      </span>
                    </small>
                  </CardWithValue>
              </div>
            </Col>
          </Row>
        :
            <Row noGutters className="mb-2">
              <Col>
              {
                !loader && previous.store ?
                  <Card body inverse color="secondary">
                    <h4 text="danger">No Data Found</h4>
                    {previous.type.value === "daily" ?
                      <p>
                        No one visited {previous.store.label} branch on {moment(previous.startDate).format("ll")}
                      </p>
                      : previous.type.value === "weekly" ?
                      <p>
                        No one visited {previous.store.label} branch during the week from {moment(previous.year.value, "YYYY").week(previous.week.value).startOf("week").startOf("day").format('ll')} to {moment(previous.year.value, "YYYY").week(previous.week.value).endOf("week").endOf("day").format('ll')}
                      </p>
                      : previous.type.value === "monthly" ?
                      <p>
                        No one visited {previous.store.label} branch during {previous.month.label}, {previous.year.value}
                      </p>
                      : previous.type.value === "yearly" ?
                      <p>
                        No one visited {previous.store.label} branch during year {moment(previous.startDate).format("YYYY")}
                      </p>
                      :
                      <p>
                        No one visited {previous.store.label} branch during given interval.
                      </p>
                    }
                  </Card>
                  : <></>
                }
              </Col>
            </Row>
      }
      { insights.length > 0 &&
        // <Row noGutters className="mb-2">
        //   <Col md={6} className="pr-1">
        //     <BarComparison data={insights.map((val, index)=>{return val.total})} labels={insights.map((val, index)=>{ return getDuration(val._id)})}></BarComparison>
        //   </Col>
        //   <Col md={6} className="pl-1">
        //     <PieComparison data={insights.map((val, index)=>{return {id: index, value:val.total, name: getDuration(val._id), color:colors[index]}})}></PieComparison>
        //   </Col>
        // </Row>
          // <PeakHours title="Peak Hours" insights={insights} colors={colors} getDuration={getDuration}></PeakHours>
          <Row noGutters>
            {
              filter.type.value == "daily" &&
              <>
                <Col md={6} className="px-1 mb-2">
                  <BarComparison cardTitle="Peak Hours" data={raw.peak_hours.map((val, index)=>{return val.total})} labels={raw.peak_hours.map((val, index)=>{ return getDuration(typeof val._id === "string"?val._id : ""+val._id.hour, 'daily')})}></BarComparison>
                </Col>

                <Col md={6} className="px-1 mb-2">
                  <PieComparison data={raw.peak_hours.map((val, index)=>{return {id: index, value:val.total, name: getDuration(typeof val._id === "string"?val._id : ""+val._id.hour, 'daily'), color:colors[index]}})}></PieComparison>
                </Col>
              </>
            }
            {
              (filter.type.value === "weekly" || filter.type.value === "monthly" || filter.type.value === "yearly") && raw.peak_hours !== undefined &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Peak Hours" data={raw.peak_hours.map((val, index)=>{return val.total})} labels={raw.peak_hours.map((val, index)=>{ return getDuration(val._id, 'days')})}></BarComparison>
              </Col>
            }
            {
              (filter.type.value === "weekly" || filter.type.value === "monthly") && raw.avg_peak_hours !== undefined &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Average Peak Hours" data={raw.avg_peak_hours.map((val, index)=>{return val.total})} labels={raw.avg_peak_hours.map((val, index)=>{ return getDuration(typeof val._id === "string"?val._id : ""+val._id.hour, 'daily')})}></BarComparison>
              </Col>
            }
            {
              filter.type.value === "yearly" && raw.avg_peak_hour !== undefined &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Average Peak Hours" data={raw.avg_peak_hour.map((val, index)=>{return val.total})} labels={raw.avg_peak_hour.map((val, index)=>{ return getDuration(typeof val._id === "string"?val._id : ""+val._id.hour, 'daily')})}></BarComparison>
              </Col>
            }
            {
              (filter.type.value === "weekly" || filter.type.value === "monthly" || filter.type.value === "yearly") && raw.top_days !== undefined && raw.top_days.length > 0 &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Peak Days" data={raw.top_days.map((val, index)=>{return val.total})} labels={raw.top_days.map((val, index)=>{ return getDuration(val._id, 'weekly')})}></BarComparison>
              </Col>
            }
            {
              (filter.type.value === "monthly" || filter.type.value === "yearly") && raw.peak_weeks !== undefined &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Peak Weeks" data={raw.peak_weeks.map((val, index)=>{return val.total})} labels={raw.peak_weeks.map((val, index)=>{ return getDuration(val._id, 'monthly')})}></BarComparison>
              </Col>
            }
            {
              filter.type.value === "yearly" && raw.top_months !== undefined &&
              <Col md={6} className="px-1 mb-2">
                <BarComparison cardTitle="Peak Months" data={raw.top_months.map((val, index)=>{return val.total})} labels={raw.top_months.map((val, index)=>{ return getDuration(val._id, 'yearly')})}></BarComparison>
              </Col>
            }
          </Row>

      }
      {
        insights.length > 0 &&
        <Card>
          <CardHeader>
            <Row className="">
              <Col sm={12} md={6}><h4 className="m-0">{tendency.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();})}</h4></Col>
              <Col sm={12} md={6} className="text-right">
                <Button color={tendency === 'mean' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setTendency("mean")}>Mean</Button> 
                <Button color={tendency === 'median' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setTendency("median")}>Median</Button>
                <Button color={tendency === 'mode' ? "danger" : "secondary"} className="btn-sm" onClick={()=>setTendency("mode")}>Mode</Button>
              </Col>
            </Row>
            <CenteralTendencyExplain tendency={tendency}/>
          </CardHeader>
          <CardBody>
            <Row>
              <Col className="text-center">
                <FontAwesomeIcon icon="user" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>Total Visitors</small></p>
              </Col>
              <Col className="text-center">
                <FontAwesomeIcon icon="male" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>Males</small></p>
              </Col>
              <Col className="text-center">
                <FontAwesomeIcon icon="female" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>Females</small></p>
              </Col>
              <Col className="text-center">
                <FontAwesomeIcon icon="child" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>Kids</small></p>
              </Col>
              <Col className="text-center">
                <FontAwesomeIcon icon="male" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>Adults</small></p>
              </Col>
              {/* <Col className="text-center">
                <FontAwesomeIcon icon="mask" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>With Mask</small></p>
              </Col>
              <Col className="text-center">
                <FontAwesomeIcon icon="mask" transform="up-1.5" color="danger"/>
                <p className="m-0"><small>No Mask</small></p>
              </Col> */}
            </Row>
            {
              tendency === "mean" &&
              <Row>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.adult|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.male|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.female|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.kid|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.adult|0}</h4>
                </Col>
                {/* <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.mask|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mean?.noMask|0}</h4>
                </Col> */}
              </Row>
            }
            { tendency === "median" &&
              <Row>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.adult|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.male|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.female|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.kid|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.adult|0}</h4>
                </Col>
                {/* <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.mask|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.median?.noMask|0}</h4>
                </Col> */}
              </Row>
            }
            { tendency === "mode" &&
              <Row>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.adult|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.male|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.female|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.kid|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.adult|0}</h4>
                </Col>
                {/* <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.mask|0}</h4>
                </Col>
                <Col className="text-center">
                  <h4 className="m-0">{raw?.mode?.noMask|0}</h4>
                </Col> */}
              </Row>
            }
          </CardBody>
        </Card>
      }
    </Fragment>
  );
}

export default Insight;

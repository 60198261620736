import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Progress, Label, Input, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../helpers/requests';
import { login } from '../redux/actions/authActions';

const EditSections = () => {
    let params = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state)=> state.auth.user);
    const stores = user.access.map((val, index)=>{
        return {...val, value: val._id, label: val.label};
    });
    const floor = stores[0].floors[0];
    const [areaSections, setAreaSections] = useState(floor.sections.filter(f=>f.type === "item"));
    const [loading, setLoading] = useState(false);
    const updateAreaSection = (val, index) => {
        var area = areaSections.slice();
        area[index].label = val;
        setAreaSections(area);
    }

    const saveSections = async () => {
        for(var i=0; i<floor.sections.length; i++){
            for(var j=0; j<areaSections.length; j++){
                if(floor.sections[i]._id == areaSections[j]._id){
                    floor.sections[i] = areaSections[j];
                }
            }
        }
        delete floor.__v;
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(floor),
            headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
        };
        setLoading(true);
        fetch(baseUrl+"floors/"+floor._id, requestOptions).then(res => {
            return res.json();
        }).then(data => {
            setLoading(false);
            if(data.status == true){
                user.access[0].floors[0] = data.data;
                localStorage.setItem("user", JSON.stringify(user));
                dispatch(login(user));
            }
        }).catch(err =>{
            setLoading(false);
            console.warn(err);
        });
    }

    return (
        <Fragment>
            <Row noGutters className="mb-3">
                <Col>
                    <h4 className="my-3">Edit Sections</h4>
                </Col>
            </Row>
            <Row>
            {
                areaSections.map((section, index) => {
                    return (
                        <Col sm={12} md={4} lg={3} className="mb-3" key={"section-"+index}>
                            <Label>Slot {index+1}:</Label>
                            <Input value={section.label} onChange={(e)=> updateAreaSection(e.target.value, index)}/>
                        </Col>
                    )
                })
            }
            </Row>
            <Row noGutters className="mb-3">
                <Col className="text-right text-end">
                    <Button className="btn-danger" onClick={()=>saveSections()} disabled={loading}>{loading ? 'Saving ...' : 'Save Sections'}</Button>
                </Col>
            </Row>
        </Fragment>
    )
}

export default EditSections;

import { actionTypes } from "../constants/action-types";

const localUser = localStorage.getItem("user");
const initialState = {
    user: !localUser ? null : JSON.parse(localUser),
    routes: [
        { name: 'Summary', to: '/', exact: true, icon: 'check-square' },
        { name: 'Visitors', to: '/visitors', exact: true, icon: 'poll' },
        { name: 'Insights', to: '/insights', icon: 'eye' },
        { name: 'Heatmap', to: '/heatmaps', icon: ['fab', 'hotjar'] },
        { name: 'Customer Journey', to: '/journey', icon: 'exchange-alt' },
        { name: 'Vehicles', to: '/vehicles', icon: 'tag' },
        { name: 'Change Alerts', to: '/alerts', icon: 'tag' },
        { name: 'Comparison', to: '/comparison', icon: 'exchange-alt' },
        { name: 'Reporting', to: '/reporting', icon: 'book' },
        { name: 'Stores', to: '/stores', icon: 'star' }
    ]
}

export const authReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.LOGIN:
            return { ...state, user: payload };
        case actionTypes.ROUTES:
            return { ...state, routes: payload };
        case actionTypes.LOGOUT:
            localStorage.removeItem("user");
            return { ...state, user: payload };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/action-types";
import moment from 'moment';
import footfall from '../../data/dashboard/footfall';
import age from '../../data/dashboard/age';
import gender from '../../data/dashboard/gender';
import mask from '../../data/dashboard/mask';
import dwell_time from '../../data/dashboard/dwellTime';
import { graphColors } from '../../helpers/utils';


const tempSeries = (series = [], color = graphColors.primary, title = "", type = "line", smooth = false) => {
    //const grays = getGrays(false);
    return {
        title: "Title_" + title,
        label: "Label_" + title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20
    }
}

const getLabels = (startDate, endDate, sid) => {
    var labels = [];

    let filterStart = (new Date(startDate)).toISOString();
    let filterEnd = (new Date(endDate)).toISOString()
    if (endDate.diff(startDate, "day") <= 1 && filterStart >= '2022-04-02T00:00:00.000Z' && filterEnd <= '2022-05-03T00:00:00.000Z') {

        labels.push('04:00 am', '05:00 am', '06:00 am', '07:00 am', '08:00 am', '09:00 am', '10:00 am', '11:00 am', '12:00 pm', '01:00 pm', '02:00 pm', '03:00 pm', '04:00 pm', '05:00 pm', '06:00 pm', '07:00 pm', '08:00 pm', '09:00 pm', '10:00 pm', '11:00pm', '12:00am', '01:00am', '02:00am', '03:00 am',)
    }
    else {
        let start = moment(startDate);
        let end = moment(endDate);
        if (end.diff(start, "day") <= 1) {
            while (end.diff(start, "hour") > 0) {
                labels.push(start.format("hh:mm a"));
                start = start.add(1, "hour");
            }
        } else {
            while (end.diff(start, "day") >= 0) {
                labels.push(start.format("MMM D"));
                start = start.add(1, "day");
            }
        }
    }
    return labels;
}

const compileData = (startDate, endDate, rawdata, entity, title,sid) => {
    var data = [];
    // let labels = ['08:00 am', '09:00 am', '10:00 am', '11:00 am', '12:00 pm', '01:00 pm', '02:00 pm', '03:00 pm', '04:00 pm', '05:00 pm', '06:00 pm', '07:00 pm', '08:00 pm', '09:00 pm', '10:00 pm','11:00pm', '12:00am', '01:00am', '02:00am','03:00 am', '04:00 am' , '05:00 am', '06:00 am', '07:00 am']
    let _ids = ['04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03',]
    var index = 0;
    let start = moment(startDate);
    let end = moment(endDate);
    if (end.diff(start, "day") <= 1) {
        rawdata = rawdata.sort((a, b) => { return a._id - b._id });
        console.log('');
        let filterStart = (new Date(startDate)).toISOString();
        let filterEnd = (new Date(endDate)).toISOString()
        if (endDate.diff(startDate, "day") <= 1 && filterStart >= '2022-04-02T00:00:00.000Z' && filterEnd <= '2022-05-03T00:00:00.000Z') {
            _ids.map((id) => {
                let _data = rawdata.filter((item) => item._id === id)
                if (_data.length) {
                    data.push(_data[0][entity])
                }
                else {
                    data.push(0)
                }
            })
        }
        else {
            while (end.diff(start, "hour") > 0) {
                if (rawdata.length && index < rawdata.length) {
                    if (start.format("HH") === rawdata[index]._id) {
                        console.log('start.format("HH") rawdata[index]._id ', start.format("HH"), rawdata[index]._id);

                        data.push(rawdata[index][entity]);
                        index++;
                    } else {
                        data.push(0);
                    }
                } else {
                    data.push(0);
                }
                start = start.add(1, "hour");
            }
        }
    }
    else {
        rawdata = rawdata.sort((a, b) => { return new Date(a._id) - new Date(b._id) });
        while (end.diff(start, "day") >= 0) {
            if (rawdata.length && index < rawdata.length) {
                if (start.format("YYYY-MM-DD") === rawdata[index]._id) {
                    data.push(rawdata[index][entity]);
                    index++;
                } else {
                    data.push(0);
                }
            } else {
                data.push(0);
            }
            start = start.add(1, "day");
        }
    }
    console.log('datadata', data);
    return tempSeries(data, ["total", "kid", "male", "mask", "dwell_time"].indexOf(entity) >= 0 ? graphColors.primary : graphColors.secondary, title);
}

const downloadOptions = [
    { value: "excel", label: "Excel" },
    { value: "pdf", label: "PDF" }
];

const initialState = {
    data: { total: 0, footfall: footfall, age: age, gender: gender, mask: mask, dwell_time: dwell_time },
    graphs: {
        labels: [],
        total: {
            data: []
        },
        age: {
            data: []
        },
        gender: {
            data: []
        },
        mask: {
            data: []
        },
        dwelltime: {
            data: []
        }
    },
    filter: {
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day"),
        downloadOptions: downloadOptions,
        downloadOption: downloadOptions[0],
    },
    loader: false
}

export const footfallReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.SET_FOOTFALL:
            var data = JSON.parse(JSON.stringify(initialState.data));
            if (payload.length) {
                var obj = payload[0];
                data.total = obj.total;
                data.footfall[0].value = obj.total;
                data.age[0].value = obj.kid;
                data.age[1].value = obj.adult;
                data.gender[0].value = obj.male;
                data.gender[1].value = obj.female;
                data.mask[0].value = obj.mask;
                data.mask[1].value = obj.noMask;
                data.dwell_time[0].value = 0;
            }
            return { ...state, data: data };
        case actionTypes.SET_FOOTFALL_GRAPH:
            console.log('filterrrr', state.filter);
            var labels = getLabels(state.filter.start, state.filter.end, state.filter.store.value);
            console.log(labels);
            let graphsData = {
                total: {
                    data: [
                        compileData(state.filter.start, state.filter.end, payload, "total", "Visitors",state.filter.store.value)
                    ]
                },
                age: {
                    data: [
                        compileData(state.filter.start, state.filter.end, payload, "kid", "Kids",state.filter.store.value),
                        compileData(state.filter.start, state.filter.end, payload, "adult", "Adults",state.filter.store.value),
                    ]
                },
                gender: {
                    data: [
                        compileData(state.filter.start, state.filter.end, payload, "male", "Males",state.filter.store.value),
                        compileData(state.filter.start, state.filter.end, payload, "female", "Females",state.filter.store.value),
                    ]
                },
                mask: {
                    data: [
                        compileData(state.filter.start, state.filter.end, payload, "mask", "Mask",state.filter.store.value),
                        compileData(state.filter.start, state.filter.end, payload, "noMask", "No Mask",state.filter.store.value),
                    ]
                },
                dwelltime: {
                    data: [
                        compileData(state.filter.start, state.filter.end, payload, "dwell_time", "Dwell Time",state.filter.store.value)
                    ]
                }
            }
            console.log(graphsData);
            return { ...state, graphs: { labels: labels, total: graphsData.total, age: graphsData.age, gender: graphsData.gender, mask: graphsData.mask, dwelltime: graphsData.dwelltime } };
        case actionTypes.SET_FOOTFALL_FILTER:
            localStorage.setItem("filter", JSON.stringify(payload));
            return { ...state, filter: payload };
        case actionTypes.SET_FOOTFALL_LOADER:
            return { ...state, loader: payload };
        default:
            return state;
    }
}
import React, { useEffect, useState } from 'react';
import { Card, CardBody} from 'reactstrap';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import Flex from '../../components/common/Flex';
import { Circle } from 'react-es6-progressbar.js';
import { rgbaColor, themeColors } from '../../helpers/utils';

const ProgressInsight = ({ visitors, duration }) => {
  const [show, setShow] = useState(true);
  const [options, setOptions] = useState({
    color: themeColors.danger,
    progress: 1,
    strokeWidth: 5,
    trailWidth: 5,
    trailColor: rgbaColor('#000', 0.15),
    easing: 'easeInOut',
    duration: 500,
    svgStyle: {
      'stroke-linecap': 'round',
      display: 'block',
      width: '100%'
    },
    text: { autoStyleContainer: false },
    // Set default step function for all animate calls
    step: (state, circle) => {
      circle.setText(`<span class='value' style='color:black;'>${visitors}<b></b></span>`);
    }
  });

  useEffect(()=>{
    setOptions({
      color: themeColors.danger,
      progress: 1,
      strokeWidth: 5,
      trailWidth: 5,
      trailColor: rgbaColor('#000', 0.15),
      easing: 'easeInOut',
      duration: 500,
      svgStyle: {
        'stroke-linecap': 'round',
        display: 'block',
        width: '100%'
      },
      text: { autoStyleContainer: false },
      // Set default step function for all animate calls
      step: (state, circle) => {
        circle.setText(`<span class='value' style='color:black;'>${visitors}<b></b></span>`);
      }
    });
    setShow(false);
    setTimeout(()=>{
      setShow(true);
    }, 10);
  }, [duration])

  return (
    <Card>
      <FalconCardHeader title="Visitors" titleTag="h6" className="py-3">
      </FalconCardHeader>
      <CardBody tag={Flex} align="center" justify="center" className="h-100">
        <div>
          {
            show === true &&
            <Circle
              progress={1}
              options={options}
              container_class="progress-circle progress-circle-dashboard"
              container_style={{ width: '155px', height: '155px' }}
            />
          }
          <div className="text-center mt-4">
            <h6 className="fs-0 mb-1">
              {duration}
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProgressInsight;

import React, { Component } from 'react';
import {Popover, PopoverBody, PopoverHeader} from 'reactstrap';



class Sections extends Component {
    constructor(props){
        super(props);
        var sections = [];
        this.props.sections.map((val)=>{
            if(this.props.show == val.type){
                sections.push(val);
            }
        });
        this.state = {
            isImageLoaded: false,
            selectedArea: props.section._id,
            sections: sections,
            popoverOpen: new Array(sections.length).fill(false)
        }
    }

    componentDidMount(){
        this.imageElement = document.createElement("img");
        this.imageElement.src = this.props.map;
        this.imageElement.addEventListener( 'load', () => {
            this.setState({isImageLoaded: true});
        });
    }

    componentWillUpdate(){
    }

    componentDidUpdate(){
        if(this.state.selectedArea != this.props.section._id){
            this.setState({selectedArea: this.props.section._id});
        }
    }

    clickArea = (section) => {
        this.props.setSection(section);
        this.setState({selectedArea: section.value});
    }

    toggle = (index, status=-1) => {
        var _popoverOpen = this.state.popoverOpen.slice();
        if(status == -1){
            _popoverOpen[index] = !_popoverOpen[index];
            this.setState({popoverOpen:_popoverOpen});
        }else{
            _popoverOpen[index] = status > 0 ? true : false ;
            this.setState({popoverOpen:_popoverOpen});
        }
    }

    render() {
        return (
            <React.Fragment>
                {   this.state.isImageLoaded &&
                    <div>
                        {this.props.dye!=null && this.props.dye.length>0 && <img width={this.imageElement.width} height={this.imageElement.height} src={this.props.dye} style={{position:"absolute", zIndex:2}} alt=""/>}
                        <div id="live-heatmap" style={{ width: this.imageElement.width+"px", height: this.imageElement.height+"px", overflow: "auto", margin: "0px", padding: "0px" }}>
                            <svg height={this.imageElement.height} width={this.imageElement.width} style={{position:"absolute", zIndex: 2}}>
                                {
                                    this.state.sections.map((section, ind) => {
                                        var points = "";
                                        var xCords = [];
                                        var yCords = [];
                                        section.positions.map((point, index)=>{
                                            if(index>0){
                                                points += " "+point.x+","+point.y;
                                            }else{
                                                points += point.x+","+point.y;
                                            }
                                            xCords.push(point.x);
                                            yCords.push(point.y);
                                            return null;
                                        });
                                        var text = {
                                            x: Math.floor(Math.min(...xCords) + (Math.max(...xCords) - Math.min(...xCords))/2), 
                                            y: Math.floor(Math.min(...yCords) + (Math.max(...yCords) - Math.min(...yCords))/2)
                                        };
                                        return(
                                            <React.Fragment key={"section_"+ind}>
                                                <text x={text.x} y={text.y} textAnchor="middle" fill="#0000008a" fontSize="16" fontWeight="900">{ind+1}</text>
                                                <polygon 
                                                    onClick={() => this.clickArea(section)} 
                                                    style={{fill: this.state.selectedArea === section._id? 'rgba(230, 55, 87,0.64)':'rgba(0,0,0,0.1)'}} 
                                                    className="it"
                                                    points={points}
                                                    onMouseEnter={()=>this.toggle(ind, 1)}
                                                    onMouseLeave={()=>this.toggle(ind, 0)}
                                                    id={"Popover-" + section._id}
                                                />
                                                <Popover
                                                    placement="top"
                                                    isOpen={this.state.popoverOpen[ind]}
                                                    target={"Popover-" + section._id}
                                                    toggle={()=>this.toggle(ind)}
                                                    key={"Popover-" + section._id}
                                                >
                                                    <PopoverHeader>{section.label}</PopoverHeader>
                                                    <PopoverBody>
                                                        Location: {section.slot}
                                                    </PopoverBody>
                                                </Popover>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                
                            </svg>
                            <img width={this.imageElement.width} height={this.imageElement.height} src={this.props.map} alt=""/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Sections;
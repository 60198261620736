import React, { Fragment, createRef } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';

import rawData from '../../data/production/data.js';

const columns = [
    {
        dataField: 'id',
        text: 'ID',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true
    },
    {
        dataField: 'state',
        text: 'State',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: false
    },
    {
        dataField: 'total',
        text: 'Stores',
        classes: 'border-0 align-middle text-center',
        headerClasses: 'border-0',
        sort: true
    },
    {
        dataField: 'avg_employees',
        text: 'Avg Employees',
        classes: 'border-0 align-middle text-center',
        headerClasses: 'border-0',
        sort: true
    },
    {
        dataField: 'avg_size',
        text: 'Avg Sqr Foot',
        classes: 'border-0 align-middle text-center',
        headerClasses: 'border-0',
        sort: true
    }
];

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
    <div className="custom-control custom-checkbox">
        <input
        className="custom-control-input"
        {...rest}
        onChange={() => {}}
        ref={input => {
            if (input) input.indeterminate = indeterminate;
        }}
        />
        <label className="custom-control-label" />
    </div>
);

const selectRow = onSelect => ({
    mode: 'checkbox',
    clickToSelect: false,
    selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
    selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
    headerColumnStyle: { border: 0, verticalAlign: 'middle' },
    selectColumnStyle: { border: 0, verticalAlign: 'middle' },
    onSelect: onSelect,
    onSelectAll: onSelect
});

const options = {
    custom: true,
    sizePerPage: 50,
    totalSize: rawData.stores.length
};

const StatesTable = ({ setIsSelected }) => {
    let table = createRef();
    
    const onSelect = () => {
        setImmediate(() => {
        setIsSelected(!!table.current.selectionContext.selected.length);
        });
    };

    var myStates = rawData.stores.map((state, index)=>{
        state["id"] = index + 1;
        state["state"] = <Link to={"stores/state/"+(index+1)}>{state["state"]}</Link>
        state["avg_employees"] = Math.round(rawData.totalEmployes/rawData.totalStores)+Math.round((Math.random()>0.5?-1:1)*Math.random()*20);
        var size = Math.random();
        state["avg_size"] = 45000+Math.round((size>0.5?-1:1)*Math.random()*15000);
        return state;
    })

    return (
        <Row>
            <Col>
                <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => {
                    const lastIndex = paginationProps.page * paginationProps.sizePerPage;

                    return (
                    <Fragment>
                        <div className="table-responsive">
                        <BootstrapTable
                            ref={table}
                            bootstrap4
                            keyField="id"
                            data={myStates}
                            columns={columns}
                            selectRow={selectRow(onSelect)}
                            bordered={false}
                            classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                            rowClasses="btn-reveal-trigger border-top border-200"
                            headerClasses="bg-200 text-900 border-y border-200"
                            {...paginationTableProps}
                        />
                        </div>
                    </Fragment>
                    );
                }}
                </PaginationProvider>
            </Col>
        </Row>
    );
};

export default StatesTable;

import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { graphColors } from '../../helpers/utils';
import Select from 'react-select';
import Datetime from 'react-datetime';

const inputStyle = () => { 
    return {
        background: "#FFFFFF",
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "8px",
        border: "1px solid transparent"
    }
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected?graphColors.primary:'',
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "0px",
    }),
    control: (provided) => ({
        ...provided,
        height: "40px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "8px",
        border: "1px solid transparent"
    })
}

class CompareFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        const { date, cdate, compare, compareTypes, type, types, store, cstore, stores, floor, floors, week, cweek, weeks, month, cmonth, months, year, cyear, years, hour, chour, hours, ehours } = this.props.filter;
        return (
            <Row>
                <Col>
                    <Row noGutters className="mb-2">
                        <Col md={3}>
                            <Select
                                value={type}
                                onChange={value=>this.props.setType(value)}
                                options={types}
                                labelledBy="Interval"
                                placeholder="Interval"
                                className="mr-md-2 mb-1 react-select"
                                styles={customStyles}
                            />
                        </Col>
                        {
                            compare.value === "single" &&
                            <Col md={3}>
                                <Select
                                    value={store}
                                    onChange={value=>this.props.setStore(value)}
                                    options={stores}
                                    labelledBy="Store"
                                    placeholder="Store"
                                    className="mr-md-2 mb-1 react-select"
                                    styles={customStyles}
                                />
                            </Col>
                        }
                        {
                            compare.value === "multiple" &&
                            <React.Fragment>
                                <Col md={3}>
                                    <Select
                                        value={store}
                                        onChange={value=>this.props.setStore(value)}
                                        options={stores}
                                        labelledBy="Store"
                                        placeholder="Store"
                                        className="mr-md-2 mb-1 react-select"
                                        styles={customStyles}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Select
                                        value={cstore}
                                        onChange={value=>this.props.setCStore(value)}
                                        options={stores}
                                        labelledBy="Store"
                                        placeholder="Store"
                                        className="mr-md-2 mb-1 react-select"
                                        styles={customStyles}
                                    />
                                </Col>
                            </React.Fragment>
                        }
                    </Row>
                    <Row noGutters className="mb-2">
                        { type.value === "daily" && 
                        <Col md={2}>
                            <Datetime
                                inputProps={{"placeholder":"Select Day"}}
                                timeFormat={false}
                                value={date}
                                onChange={this.props.setStartDate}
                                placeholder="Day"
                                className="mr-md-2 mb-1 filter-date"
                            />
                        </Col>
                        }
                        { type.value != "daily" && 
                        <>
                            <Col md={2}>
                                <Select
                                    value={year}
                                    onChange={value=>this.props.setYear(value)}
                                    options={years}
                                    labelledBy="Year"
                                    placeholder="Year"
                                    className="mr-md-2 mb-1 react-select"
                                    styles={customStyles}
                                />
                            </Col>
                            { type.value === "weekly" &&
                            <Col md={2}>
                                <Select
                                    value={week}
                                    onChange={value=>this.props.setWeek(value)}
                                    options={weeks}
                                    labelledBy="Week"
                                    placeholder="Week"
                                    className="mr-md-2 mb-1 react-select"
                                    styles={customStyles}
                                />
                            </Col>
                            }
                            { type.value === "monthly" &&
                            <Col md={2}>
                                <Select
                                    value={month}
                                    onChange={value=>this.props.setMonth(value)}
                                    options={months}
                                    labelledBy="Month"
                                    placeholder="Month"
                                    className="mr-md-2 mb-1 react-select"
                                    styles={customStyles}
                                />
                            </Col>
                            }
                        </>
                        }
                        {
                            compare.value === 'single' &&
                            <>
                                {
                                    type.value === "daily" && 
                                    <Col md={2}>
                                        <Datetime
                                            inputProps={{"placeholder":"Select Day"}}
                                            timeFormat={false}
                                            value={cdate}
                                            onChange={this.props.setCStartDate}
                                            placeholder="Day"
                                            className="mr-md-2 mb-1 filter-date"
                                        />
                                    </Col>
                                    }
                                    { type.value != "daily" && 
                                    <>
                                        <Col md={2}>
                                            <Select
                                                value={cyear}
                                                onChange={value=>this.props.setCYear(value)}
                                                options={years}
                                                labelledBy="Year"
                                                placeholder="Year"
                                                className="mr-md-2 mb-1 react-select"
                                                styles={customStyles}
                                            />
                                        </Col>
                                        { type.value === "weekly" &&
                                        <Col md={2}>
                                            <Select
                                                value={cweek}
                                                onChange={value=>this.props.setCWeek(value)}
                                                options={weeks}
                                                labelledBy="Week"
                                                placeholder="Week"
                                                className="mr-md-2 mb-1 react-select"
                                                styles={customStyles}
                                            />
                                        </Col>
                                        }
                                        { type.value === "monthly" &&
                                        <Col md={2}>
                                            <Select
                                                value={cmonth}
                                                onChange={value=>this.props.setCMonth(value)}
                                                options={months}
                                                labelledBy="Month"
                                                placeholder="Month"
                                                className="mr-md-2 mb-1 react-select"
                                                styles={customStyles}
                                            />
                                        </Col>
                                        }
                                    </>
                                }
                            </>
                        }
                        <Col md={2}>
                            <Select
                                value={hour}
                                onChange={value=>this.props.setHour(value)}
                                options={hours}
                                labelledBy="Start Time"
                                placeholder="Start Time"
                                className="mr-md-2 mb-1 react-select"
                                styles={customStyles}
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                value={chour}
                                onChange={value=>this.props.setCHour(value)}
                                options={ehours}
                                labelledBy="Start Time"
                                placeholder="Start Time"
                                className="mr-md-2 mb-1 react-select"
                                styles={customStyles}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} className="text-right">
                    <Button className="w-100 mb-1 filter-button" style={{height: "39px"}} color={"danger"} onClick={()=>this.props.submit()}>
                        Search
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default CompareFilter;
import React, { useContext, useState, Fragment } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button, CardHeader } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { getPosition, getGrays, themeColors, rgbaColor, isIterableArray, capitalize, graphColors } from '../../helpers/utils';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

function getFormatter(params) {
    var str = "";
    for(var i=0; i<params.length; i++){
        const { seriesName, name, value } = params[i];
        if(i>0){
            str += ` | ${seriesName}: ${value}`;
        }else{
            str += `${name} | ${seriesName}: ${value}`;
        }
    }
    return str;
}

const getOption = (month, isDark) => {
    const grays = getGrays(isDark);
    return null;
};

const LineGraph = ({ className, seriesData, hideControls, title, titles}) => {
    
    var options = {
        tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: "white",
            borderColor: "#d5d5d5",
            borderWidth: 1,
            textStyle: { color: themeColors.dark },
            formatter(params) {
                return getFormatter(params);
            },
            transitionDuration: 0,
            position(pos, params, dom, rect, size) {
                return getPosition(pos, params, dom, rect, size);
            }
        },
        xAxis: {
            type: 'category',
            data: seriesData.labels,
            boundaryGap: true,
            axisPointer: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed'
                }
            },
            splitLine: { show: false },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#111', 0.01),
                    type: 'dashed'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                color: "#111",
                formatter: function(value) {
                    return `${value}`;
                },
                margin: 15
            }
        },
        yAxis: {
            type: 'value',
            axisPointer: { show: false },
            splitLine: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed'
                }
            },
            boundaryGap: true,
            axisLabel: {
                show: true,
                color: "#000",
                margin: 15
            },
            axisTick: { show: false },
            axisLine: { show: false }
        },
        series: seriesData.data,
        color: graphColors.primary,
        animationDuration: 1000,
        grid: { right: '28px', left: '60px', bottom: '15%', top: '5%' }
    };

    // let options1 = {
    //     xAxis: {
    //         type: 'category',
    //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //     },
    //     yAxis: {
    //         type: 'value'
    //     },
    //     series: [{
    //         data: [120, 200, 150, 80, 70, 110, 130],
    //         type: 'line',
    //         showBackground: true,
    //         color: graphColors.primary
    //     }]
    // }

    const [graphType, setGraphType] = useState("line");
    const setType = (type) => {
        setGraphType(type);
        options.series.forEach((series, index) => {
            series.type = type;
            if(titles){
                series.name = titles[index];
            }
        });
    }
    
    return(
        <Fragment>
            <Card className="h-100">
                <CardHeader>
                    <Row>
                        <Col>
                            {
                                title != null &&
                                <h4 className="m-0">{title}</h4>
                            }
                        </Col>
                        <Col className="text-right">
                            {
                                !hideControls &&
                                <>
                                    <Button color={graphType == 'bar' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setType("bar")}>Bar Graph</Button> 
                                    <Button color={graphType == 'line' ? "danger" : "secondary"} className="btn-sm" onClick={()=>setType("line")}>Line Graph</Button>
                                </>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactEchartsCore echarts={echarts} option={options} style={{ minHeight: '10.75rem', height: "12.75rem"}} />
                </CardBody>
            </Card>
        </Fragment>
    )
}

  export default LineGraph;
import {actionTypes} from '../constants/action-types'

export const login = (user) => {
    return {
        type: actionTypes.LOGIN,
        payload: user
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT,
        payload: {}
    }
}

import { actionTypes } from "../constants/action-types";
import { graphColors } from '../../helpers/utils';
import moment from 'moment';

const tempSeries = (series = [], color=graphColors.primary, title="", type="bar", smooth = false) => {
    return{
        title: "Title_"+title,
        label: "Label_"+title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
    }
}

const lineSeries = (series = [], color=graphColors.primary, title="", type="line", smooth = false) => {
    return{
        title: "Title_"+title,
        label: "Label_"+title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
    }
}

const getLineLabels = (startDate, endDate) => {
    var labels = [];
    let start = moment(startDate);
    let end = moment(endDate);
    if(end.diff(start, "day") <= 1){
        while(end.diff(start, "hour") > 0){
            labels.push(start.format("hh:mm a"));
            start = start.add(1, "hour");
        }
    }else{
        while(end.diff(start, "day") >= 0){
            labels.push(start.format("MMM D"));
            start = start.add(1, "day");
        }
    }
    return labels;
}

const getTempalte = (id) => {
    return {_id: id, footfall: 0, left: 0, top: 0, right: 0, bottom: 0, ex_left: 0, ex_top:0, ex_right: 0, ex_bottom: 0, avg_dt: 0};
}

const getSection = (type, id) =>{
    var temp = {...getTempalte(id)};
    type.forEach((row)=>{
        if(row._id === id){
            temp = row;
        }
    });
    return temp;
}

const getSectionTotal = (male, female, id) =>{
    var tempMale = {...getTempalte(id)};
    var tempFemale = {...getTempalte(id)};
    male.forEach((row)=>{
        if(row._id === id){
            tempMale = row;
        }
    });
    female.forEach((row)=>{
        if(row._id === id){
            tempFemale = row;
        }
    });
    return {
        _id: id, 
        footfall: tempMale.footfall + tempFemale.footfall, 
        left: tempMale.left + tempFemale.left, 
        top: tempMale.top + tempFemale.top, 
        right: tempMale.right + tempFemale.right, 
        bottom: tempMale.bottom + tempFemale.bottom, 
        ex_left: tempMale.ex_left + tempFemale.ex_left, 
        ex_top: tempMale.ex_top + tempFemale.ex_top, 
        ex_right: tempMale.ex_right + tempFemale.ex_right, 
        ex_bottom: tempMale.ex_bottom + tempFemale.ex_bottom,
        avg_dt: tempMale.avg_dt + tempFemale.avg_dt
    };
}

const compileData = (data, sections) => {
    data["total"] = [];
    sections.map((section) => {
        data.total.push(getTempalte(section._id));
    });
    var temp = {total: [], male: [], female: [], adult: [], kid: []};
    sections.map((section) => {
        temp.total.push(getSectionTotal(data.male, data.female, section._id));
        temp.male.push(getSection(data.male, section._id));
        temp.female.push(getSection(data.female, section._id));
        temp.adult.push(getSection(data.adults, section._id));
        temp.kid.push(getSection(data.kids, section._id));
    });
    return temp;
}

const compileLineData = (startDate, endDate, rawdata, entity, title) => {
    var data = [];
    var index = 0;
    let start = moment(startDate);
    let end = moment(endDate);
    if(end.diff(start, "day") <= 1){
        rawdata = rawdata.sort((a,b)=>{return a._id-b._id});
        while(end.diff(start, "hour") > 0){
            if(rawdata.length && index < rawdata.length){
                if(start.format("HH") === rawdata[index]._id){
                    data.push(Math.round(rawdata[index][entity])%120);
                    index++;
                }else{
                    data.push(0);
                }
            }else{
                data.push(0);
            }
            start = start.add(1, "hour");
        }
    }else{
        rawdata = rawdata.sort((a,b)=>{return new Date(a._id)-new Date(b._id)});
        while(end.diff(start, "day") >= 0){
            if(rawdata.length && index < rawdata.length){
                if(start.format("YYYY-MM-DD") === rawdata[index]._id){
                    data.push(Math.round(rawdata[index][entity])%120);
                    index++;
                }else{
                    data.push(0);
                }
            }else{
                data.push(0);
            }
            start = start.add(1, "day");
        }
    }
    return lineSeries(data, ["avg_dt"].indexOf(entity) >= 0 ? graphColors.primary : graphColors.secondary, title);
}

const initialState = {
    labels: getLineLabels(new Date(), new Date()),
    data: {total: [], male: [], female: [], adults: [], kids: []},
    graph: null,
    filter: {
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        section: null,
        sections: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day")
    },
    loader: false
}

export const categoryReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_CATEGORY:
            var data = compileData(payload, state.filter.sections);
            return { ...state, data: data };
        case actionTypes.SET_CATEGORY_GRAPH:
            var temp = {
                labels: getLineLabels(state.filter.start, state.filter.end),
                graph: compileLineData(state.filter.start, state.filter.end, payload, "avg_dt", "Average Dwell Time (s)")
            }
            return { ...state, ...temp};
        case actionTypes.SET_CATEGORY_FILTER:
                return { ...state, filter: payload };
        case actionTypes.SET_CATEGORY_LOADER:
                return { ...state, loader: payload };
        default:
            return state;
    }
}
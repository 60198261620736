import {actionTypes} from '../constants/action-types'

export const setInsight = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT,
        payload: payload
    }
}

export const setInsightData = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_DATA,
        payload: payload
    }
}


export const setInsightFilter = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_FILTER,
        payload: payload
    }
}


export const setInsightProgress = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_PROGRESS,
        payload: payload
    }
}


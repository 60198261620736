import React, { Fragment, Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PageHeader from '../components/common/PageHeader';
import FalconCardHeader from '../components/common/FalconCardHeader';
import live from '../assets/img/live.png';;
class Realtime extends Component{

  render(){
    return(
      <Fragment>
        <PageHeader
          title="Live Traffic is not yet activated"
          description="
            Live traffic gives you an instant insights of how your stores are performing at any given instant.
          "
          className="mb-3"
        >
        </PageHeader>
        <Card>
          <FalconCardHeader title="Sample Live Traffic" />
          <CardBody>
            <Row>
              <Col className="h-100 valign-middle">
                <h5 className="font-italic">"A picture is worth a thousand words"</h5>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={live} className="rounded w-100" alt=""/>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    )
  }
}

// const Realtime = () => (
//   <Fragment>
//     <Row noGutters className="mb-3">
//       <Col lg="4" className="pr-lg-2">
//         <ActiveUsersBarChart />
//       </Col>
//       <Col lg="8" className="pl-lg-2">
//         <ActiveUsersMap />
//       </Col>
//     </Row>
//     <Row noGutters>
//       <Col>
//         <div className="card-deck">
//           <CardWithValue rate="-0.23%" title="Males Now" color="info" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="-0.23%" title="Females Now" color="info" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="0.0%" title="Missed" color="info" linkText="">
//             73.46k
//           </CardWithValue>
//         </div>
//       </Col>
//     </Row>
//     <Row noGutters>
//       <Col>
//         <div className="card-deck">
//           <CardWithValue rate="-0.23%" title="Kids Now" color="primary" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="-0.23%" title="Adluts Now" color="primary" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="0.0%" title="Missed" color="primary" linkText="">
//             73.46k
//           </CardWithValue>
//         </div>
//       </Col>
//     </Row>
//     <Row noGutters className="mb-2">
//       <Col>
//         <div className="card-deck">
//           <CardWithValue rate="-0.23%" title="With Masks Now" color="success" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="-0.23%" title="Without Masks Now" color="success" linkText="">
//             58.39k
//           </CardWithValue>
//           <CardWithValue rate="0.0%" title="Missed" color="success" linkText="">
//             73.46k
//           </CardWithValue>
//         </div>
//       </Col>
//     </Row>
//     <Row noGutters className="mb-3">
//       <Col>
//         <div className="card-deck">
//           <CardWithRealTimeBar title="Males Now" color="info"/>
//           <CardWithRealTimeBar title="Females Now" color="info"/>
//         </div>
//       </Col>
//     </Row>
//     <Row noGutters className="mb-3">
//       <Col>
//         <div className="card-deck">
//           <CardWithRealTimeBar title="Kids Now" color="primary"/>
//           <CardWithRealTimeBar title="Adults Now" color="primary"/>
//         </div>
//       </Col>
//     </Row>
//     <Row noGutters className="mb-3">
//       <Col>
//         <div className="card-deck">
//           <CardWithRealTimeBar title="With Masks Now" color="success"/>
//           <CardWithRealTimeBar title="Wihtout Masks Now" color="success"/>
//         </div>
//       </Col>
//     </Row>
//   </Fragment>
// );

export default Realtime;

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import CompareFilter from './template/CompareFilter';
import { setComparisonFilter, setComparisonLoader } from '../redux/actions/comparisonActions';
import { useSelector, useDispatch } from 'react-redux';
import { comparisonRequest } from '../helpers/requests';
import moment from 'moment';
import { getWeeks } from '../redux/reducers/comparisonReducer';
import { graphColors } from '../helpers/utils';
import Select from 'react-select';
import CompareHeader from './template/CompareHeader';
import CompareSingle from './template/CompareSingle';
import CompareMultiple from './template/CompareMultiple';
import Progress from 'reactstrap/lib/Progress';

const customStyles = {
  option: (provided, state) => ({
      ...provided,
      background: state.isSelected?graphColors.primary:'',
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "0px",
  }),
  control: (provided) => ({
      ...provided,
      height: "40px",
      background: "#FFFFFF",
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "8px",
      border: "1px solid transparent"
  })
}

const Comparison = () => {
  var dispatch = useDispatch();
  var filter = useSelector((state)=> state.compare.filter);
  var loader = useSelector((state)=> state.compare.loader);
  const user = useSelector((state)=> state.auth.user);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });
  const [selected, setSelected] = useState(0);
  const setType = (type) =>{
    dispatch(setComparisonFilter({...filter, type: type}));
  }
  const setStartDate = (date) =>{
    dispatch(setComparisonFilter({...filter, date: date}));
  }
  const setWeek = (week) =>{
    dispatch(setComparisonFilter({...filter, week: week}));
  }
  const setMonth = (month) =>{
    dispatch(setComparisonFilter({...filter, month: month}));
  }
  const setYear = (year) =>{
    var weeks = getWeeks(year.value);
    dispatch(setComparisonFilter({...filter, year: year, weeks: weeks, week: weeks[0]}));
  }
  const setStores = (stores) => {
    dispatch(setComparisonFilter({...filter, store: stores.length?stores[0]:null, cstore: stores.length?stores[0]:null, stores: stores}));
  }
  const setStore = (store) =>{
    dispatch(setComparisonFilter({...filter, store: store}));
  }
  const setHour = (hour) =>{
    dispatch(setComparisonFilter({...filter, hour: hour}));
  }
  const setCHour = (hour) =>{
    dispatch(setComparisonFilter({...filter, chour: hour}));
  }
  const setCStartDate = (date) =>{
    dispatch(setComparisonFilter({...filter, cdate: date}));
  }
  const setCWeek = (week) =>{
    dispatch(setComparisonFilter({...filter, cweek: week}));
  }
  const setCMonth = (month) =>{
    dispatch(setComparisonFilter({...filter, cmonth: month}));
  }
  const setCYear = (year) =>{
    var weeks = getWeeks(year.value);
    dispatch(setComparisonFilter({...filter, cyear: year, cweeks: weeks, cweek: weeks[0]}));
  }
  const setCStore = (store) =>{
    dispatch(setComparisonFilter({...filter, cstore: store}));
  }
  const setFloor = (floor) =>{
    dispatch(setComparisonFilter({...filter, floor: floor}));
  }
  const setCompareType = (comparisonType) => {
    dispatch(setComparisonFilter({...filter, compare: comparisonType}))
  }

  const getComparison = () => {
    var startDate;
    var startCDate;
    var endDate;
    var endCDate;
    try{
      if(filter.type.value === "daily"){
          startDate = moment(new Date(filter.date)).startOf("day");
          startCDate = moment(new Date(filter.cdate)).startOf("day");
          endDate = moment(new Date(filter.date)).endOf("day");
          endCDate = moment(new Date(filter.cdate)).endOf("day");
      }else if(filter.type.value === "weekly"){
          startDate = moment(filter.year.value, "YYYY").week(filter.week.value).startOf("week").startOf("day");
          startCDate = moment(filter.cyear.value, "YYYY").week(filter.cweek.value).startOf("week").startOf("day");
          endDate = moment(filter.year.value, "YYYY").week(filter.week.value).endOf("week").endOf("day");
          endCDate = moment(filter.cyear.value, "YYYY").week(filter.cweek.value).endOf("week").endOf("day");
      }else if(filter.type.value === "monthly"){
          startDate = moment(filter.year.value+'-'+filter.month.value, "YYYY-MM").startOf("month").startOf("day");
          startCDate = moment(filter.cyear.value+'-'+filter.cmonth.value, "YYYY-MM").startOf("month").startOf("day");
          endDate = moment(filter.year.value+'-'+filter.month.value, "YYYY-MM").endOf("month").endOf("day");
          endCDate = moment(filter.cyear.value+'-'+filter.cmonth.value, "YYYY-MM").endOf("month").endOf("day");
      }else if(filter.type.value === "yearly"){
          startDate = moment(filter.year.value, "YYYY").startOf("year").startOf("day");
          startCDate = moment(filter.cyear.value, "YYYY").startOf("year").startOf("day");
          endDate = moment(filter.year.value, "YYYY").endOf("year").endOf("day");
          endCDate = moment(filter.cyear.value, "YYYY").endOf("year").endOf("day");
      }
      setSelected(0);
      var params = {
        sd:[startDate, startCDate],
        ed:[endDate,endCDate],
        st:[filter.hour.value,filter.hour.value],
        et:[filter.chour.value,filter.chour.value],
        t:filter.type.value[0],
        type:filter.compare.value,
        sid:[filter.store?filter.store.value:stores[0]._id, filter.cstore?filter.cstore.value:stores[0]._id]
      }
        
      var requestData = {
        params: params,
        dispatch: dispatch
      };
      comparisonRequest(requestData);
    }catch(e){
    }
  }

  useEffect(()=>{
    setStores(stores);
    setTimeout(()=>{
      getComparison();
    }, 200);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4 className="my-3">Comparisons</h4>
        </Col>
        <Col md={2}>
          
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <CompareHeader icon="star" title="Comparison" compare={filter.compare} compareTypes={filter.compareTypes} setCompareType={(val) => setCompareType(val)}/>
        </Col>
      </Row>
      <CompareFilter 
        filter={filter}
        setCompareType={(e)=>setCompareType(e)}
        setType={(e)=>setType(e)}
        setStore={(e)=>setStore(e)}
        setFloor={(e)=>setFloor(e)}
        setStartDate={(e)=>setStartDate(e)}
        setWeek={(e)=>setWeek(e)}
        setMonth={(e)=>setMonth(e)}
        setYear={(e)=>setYear(e)}
        setCStore={(e)=>setCStore(e)}
        setCStartDate={(e)=>setCStartDate(e)}
        setCWeek={(e)=>setCWeek(e)}
        setCMonth={(e)=>setCMonth(e)}
        setCYear={(e)=>setCYear(e)}
        setHour={(e)=>setHour(e)}
        setCHour={(e)=>setCHour(e)}
        submit={()=>getComparison()}
      />
      <Row>
        <Col style={{height: "20px"}}>
          {
            loader &&
            <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {
            filter.compare.value === "single" && <CompareSingle/> 
          }
          {
            filter.compare.value === "multiple" && <CompareMultiple/> 
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Comparison;

import React, { Component } from 'react';
import h337 from 'heatmap.js';
import scale from '../../assets/img/scale.png';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
//import heatmapData from '../../data/dashboard/heatmap.js';



class Heatmap extends Component {
    constructor(props){
        super(props);
        var sections = [];
        this.props.sections.map((val)=>{
            if(this.props.show == val.type){
                sections.push(val);
            }
        });
        this.state = {
            isImageLoaded: false,
            sections: sections,
            popoverOpen: new Array(sections.length).fill(false)
        }
    }

    componentDidMount(){
        this.imageElement = document.createElement("img");
        this.imageElement.src = this.props.map;
        this.imageElement.addEventListener( 'load', () => {
            this.setState({isImageLoaded: true});
            setTimeout(() => {
                this.heatmapInstance = h337.create({
                    container: document.querySelector('#live-heatmap'),
                    radius: 11,
                    opacity: .7,
                    visible: true,
                    gradient: {
                        0.05: '#ffffff',
                        '0.10': '#265BF9',
                        0.15: '#3785F9',
                        '0.20': '#47AFFA',
                        0.25: '#59DBFB',
                        '0.30': '#65FBF2',
                        0.35: '#65FA9D',
                        '0.40': '#64F94D',
                        0.45: '#64F92C',
                        '0.50': '#78FA26',
                        0.55: '#92FA28',
                        '0.60': '#B1FC2A',
                        0.65: '#D2FC2C',
                        '0.70': '#F5FD2F',
                        0.75: '#F8DE2C',
                        '0.80': '#EC8923',
                        0.85: '#E7601F',
                        '0.90': '#E43A1C',
                        0.95: '#E3231B',
                        '1.0': '#b80701' // highest red
                    }
                });
                setTimeout(() => {
                    let data = this.processHeatmapData(this.props.heatmapData);
                    this.heatmapInstance.setData(data);
                }, 200);
                
            }, 200);
        });
    }

    processHeatmapData = (heatmapData) => {
        let min = Number.MAX_SAFE_INTEGER-1;
        let max = Number.MIN_SAFE_INTEGER-1;
        var data = [];
        for(var i =0; i<heatmapData.length; i++){
            var  val = heatmapData[i].v;
            var record = heatmapData[i];
            // if(record.x>410 && record.x<460 && record.y>0 && record.y<100){
            //     continue;
            // }
            data.push({x:record.x, y: record.y, value: record.v}); 
            if(val < min){
                min = val;
            }
            if(val > max){
                max = val;
            }
        }
        return {max:max, data: data};
    }

    componentWillUpdate(){
    }

    componentDidUpdate(){
        try{
            if(this.state.isImageLoaded && this.heatmapInstance){
                let data = this.processHeatmapData(this.props.heatmapData);
                this.heatmapInstance.setData(data);
            }
        }catch(e){
        }
    }

    clickArea = (section) => {
        this.props.setSection(section);
        this.setState({selectedArea: section.value});
    }

    toggle = (index, status=-1) => {
        var _popoverOpen = this.state.popoverOpen.slice();
        if(status == -1){
            _popoverOpen[index] = !_popoverOpen[index];
            this.setState({popoverOpen:_popoverOpen});
        }else{
            _popoverOpen[index] = status > 0 ? true : false ;
            this.setState({popoverOpen:_popoverOpen});
        }
    }

    render() {
        return (
            <React.Fragment>
                {   this.state.isImageLoaded &&
                    <div>
                        {
                        this.props.dye!=null && this.props.dye.length>0 && 
                            <>

                            <img  src={scale} style={{position: "absolute", width: "100px", right: "20px", zIndex: 3}} alt=""/>
                            <img width={this.imageElement.width} height={this.imageElement.height} src={this.props.dye} style={{position:"absolute", zIndex:2}} alt=""/>
                            </>
                        }
                        <div id="live-heatmap" style={{ width: this.imageElement.width+"px", height: this.imageElement.height+"px", overflow: "auto", margin: "0px", padding: "0px" }}>
                            {
                                this.props.sections.length > 0 &&
                                <svg height={this.imageElement.height} width={this.imageElement.width} style={{position:"absolute", zIndex: 2}}>
                                    {
                                        this.props.sections.filter(a=>a.type=="item").map((section, ind) => {
                                            var points = "";
                                            var xCords = [];
                                            var yCords = [];
                                            section.positions.map((point, index)=>{
                                                if(index>0){
                                                    points += " "+point.x+","+point.y;
                                                }else{
                                                    points += point.x+","+point.y;
                                                }
                                                xCords.push(point.x);
                                                yCords.push(point.y);
                                                return null;
                                            });
                                            var text = {
                                                x: Math.floor(Math.min(...xCords) + (Math.max(...xCords) - Math.min(...xCords))/2), 
                                                y: Math.floor(Math.min(...yCords) + (Math.max(...yCords) - Math.min(...yCords))/2)
                                            };
                                            return(
                                                <React.Fragment key={"section_"+ind}>
                                                    <text x={text.x} y={text.y} textAnchor="middle" fill="#0000008a" fontSize="16" fontWeight="900">{ind+1}</text>
                                                    <polygon 
                                                        style={{fill: this.state.selectedArea === section._id? 'rgba(230, 55, 87,0.64)':'rgba(0,0,0,0.1)'}} 
                                                        className="it"
                                                        points={points}
                                                        onMouseEnter={()=>this.toggle(ind, 1)}
                                                        onMouseLeave={()=>this.toggle(ind, 0)}
                                                        id={"Popover-" + section._id}
                                                    />
                                                    <Popover
                                                        placement="top"
                                                        isOpen={this.state.popoverOpen[ind]}
                                                        target={"Popover-" + section._id}
                                                        toggle={()=>this.toggle(ind)}
                                                        key={"Popover-" + section._id}
                                                    >
                                                        <PopoverHeader>{section.label}</PopoverHeader>
                                                        <PopoverBody>
                                                            Location: {section.slot}
                                                        </PopoverBody>
                                                    </Popover>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    
                                </svg>
                            }
                            {/* <svg height={this.imageElement.height} width={this.imageElement.width} style={{position:"absolute", zIndex: 2}}>
                                {
                                    this.props.sections.map((section, ind) => {
                                        var points = "";
                                        section.positions.map((point, index)=>{
                                            if(index>0){
                                                points += " "+point.x+","+point.y;
                                            }else{
                                                points += point.x+","+point.y;
                                            }
                                        });
                                        return(
                                            <React.Fragment key={"section_"+ind}>
                                                <text x={section.x} y={section.y} textAnchor="middle" fill="#0000008a" fontSize="20" key={"text"+ind}>{section.title}</text>
                                                <polygon 
                                                    style={{fill: this.state.selectedArea == section._id? 'rgba(230,109,2,0.64)':'rgba(0,0,0,0.1)'}} 
                                                    className="it"
                                                    points={points}
                                                    key={"section"+ind}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                }
                                
                            </svg> */}
                            <img width={this.imageElement.width} height={this.imageElement.height} src={this.props.map} alt=""/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Heatmap;
import React, { Fragment, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Card, CardBody, CardFooter, Button} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import exportFromJSON from 'export-from-json';
import { filter } from 'lodash';



const ReportsExcel = () => {
    var graphData = useSelector((state)=> state.footfall.graphs);
    const columns = [
        {
          dataField: 'label',
          text: 'Interval',
          classes: 'border-0 align-middle',
          headerClasses: 'border-0',
          sort: true
        },
        {
          dataField: 'total',
          text: 'Total',
          classes: 'border-0 align-middle',
          headerClasses: 'border-0',
          sort: true
        },
        {
          dataField: 'male',
          text: 'Males',
          classes: 'border-0 align-middle',
          headerClasses: 'border-0',
          sort: true
        },
        {
          dataField: 'female',
          text: 'Females',
          classes: 'border-0 align-middle fs-0',
          headerClasses: 'border-0',
          sort: true
        },
        {
          dataField: 'adult',
          text: 'Adults',
          classes: 'border-0 align-middle',
          headerClasses: 'border-0',
          sort: true
        },
        {
          dataField: 'kid',
          text: 'Kids',
          classes: 'border-0 align-middle',
          headerClasses: 'border-0',
          sort: true
        },
        // {
        //   dataField: 'mask',
        //   text: 'With Mask',
        //   classes: 'border-0 align-middle',
        //   headerClasses: 'border-0',
        //   sort: true
        // },
        // {
        //   dataField: 'nomask',
        //   text: 'Without Mask',
        //   classes: 'border-0 align-middle',
        //   headerClasses: 'border-0',
        //   sort: true
        // }
    ];
    const [data, setData] = useState([]);

    const downloadExcelSheet = () => {
        var processed = data.map((row, index)=>{
            return {
                "Interval ": row.label, 
                "Total": row.total, 
                "Males": row.male,
                "Females":row.female, 
                "Adults":row.adult, 
                "Kids":row.kid, 
                "With Masks":row.mask, 
                "Without Masks":row.nomask
            };
        });
        exportFromJSON({ data: processed, fileName: "Adlytic Analytics", exportType: exportFromJSON.types.csv })
    }

    useEffect(()=>{
        var _data = [];
        graphData.labels.map((val, index) => {
            _data.push({
                label: val, 
                total: graphData.total.data[0].data[index],
                male: graphData.gender.data[0].data[index],
                female: graphData.gender.data[1].data[index],
                adult: graphData.age.data[1].data[index],
                kid: graphData.age.data[0].data[index],
                mask: graphData.mask.data[0].data[index],
                nomask: graphData.mask.data[1].data[index]
            });
        });
        setData(_data);
    }, [graphData])
    return (
        <Fragment>
            <Card>
                    <div className="table-responsive">
                        <BootstrapTable
                            bootstrap4
                            keyField="label"
                            data={data}
                            columns={columns}
                            bordered={false}
                            classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                            rowClasses="btn-reveal-trigger border-top border-200"
                            headerClasses="bg-200 text-900 border-y border-200"
                        />
                    </div>
                <CardFooter>
                    <div className="text-right">
                        <Button color="danger" className="px-5 mb-1 filter-button" onClick={()=> downloadExcelSheet()}>Export as Excel</Button>
                    </div>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export default ReportsExcel;
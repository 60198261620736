import {actionTypes} from '../constants/action-types'

export const setCategory = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY,
        payload: payload
    }
}

export const setCategoryGraph = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_GRAPH,
        payload: payload
    }
}

export const setCategoryFilter = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_FILTER,
        payload: payload
    }
}

export const setCategoryLoader = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_LOADER,
        payload: payload
    }
}


export const actionTypes = {
    LOGIN : "LOGIN",
    ROUTES : "ROUTES",
    LOGOUT : "LOGOUT",
    SET_SUMMARY: "SET_SUMMARY",
    SET_SUMARY_FILTER: "SET_SUMARY_FILTER",
    SET_CURRENT_SUMARY: "SET_CURRENT_SUMARY",
    SET_SUMARY_LOADER: "SET_SUMARY_LOADER",
    GET_SUMARY_FILTER: "GET_SUMARY_FILTER",
    SET_HEATMAP: "SET_HEATMAP",
    SET_HEATMAP_FILTER: "SET_HEATMAP_FILTER",
    SET_HEATMAP_LOADER: "SET_HEATMAP_LOADER",
    GET_HEATMAP_FILTER: "GET_HEATMAP_FILTER",
    SET_FOOTFALL: "SET_FOOTFALL",
    SET_FOOTFALL_GRAPH: "SET_FOOTFALL_GRAPH",
    SET_FOOTFALL_FILTER: "SET_FOOTFALL_FILTER",
    SET_FOOTFALL_LOADER: "SET_FOOTFALL_LOADER",
    GET_FOOTFALL_FILTER: "GET_FOOTFALL_FILTER",
    SET_CATEGORY: "SET_CATEGORY",
    SET_CATEGORY_GRAPH: "SET_CATEGORY_GRAPH",
    SET_CATEGORY_FILTER: "SET_CATEGORY_FILTER",
    SET_CATEGORY_LOADER: "SET_CATEGORY_LOADER",
    SET_INSIGHT: "SET_INSIGHT",
    SET_INSIGHT_DATA: "SET_INSIGHT_DATA",
    SET_INSIGHT_FILTER: "SET_INSIGHT_FILTER",
    SET_INSIGHT_PROGRESS: "SET_INSIGHT_PROGRESS",
    UPDATE_SECTIONS: "UPDATE_SECTIONS",
    SECTIONS_LOADER: "SECTIONS_LOADER",
    SET_COMPARISON_LOADER: "SET_COMPARISON_LOADER",
    SET_COMPARISON_FILTER: "SET_COMPARISON_FILTER",
    SET_COMPARISON: "SET_COMPARISON",
    SET_COMPARISON_GROUP: "SET_COMPARISON_GROUP",
    SET_VEHICLE: "SET_VEHICLE",
    SET_VEHICLE_GRAPH: "SET_VEHICLE_GRAPH",
    SET_VEHICLE_FILTER: "SET_VEHICLE_FILTER",
    SET_VEHICLE_LOADER: "SET_VEHICLE_LOADER",
    SET_DETECTION: "SET_DETECTION",
    SET_DETECTION_OLD: "SET_DETECTION_OLD",
    SET_DETECTION_UPDATE: "SET_DETECTION_UPDATE",
    SET_DETECTION_FILTER: "SET_DETECTION_FILTER",
    SET_DETECTION_LOADER: "SET_DETECTION_LOADER",
    SET_DETECTION_SUCCESS: "SET_DETECTION_SUCCESS",
    SET_DETECTION_ERROR: "SET_DETECTION_ERROR"
}
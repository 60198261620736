import {actionTypes} from '../constants/action-types'

export const setFootfall = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL,
        payload: payload
    }
}

export const setFootfallGraph = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_GRAPH,
        payload: payload
    }
}

export const setFootfallFilter = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_FILTER,
        payload: payload
    }
}

export const setFootfallLoader = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_LOADER,
        payload: payload
    }
}


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import LineGraph from '../../components/production/LineGraph';
import {graphColors} from '../../helpers/utils';


const tempSeries = (series = [], color=graphColors.primary, title="", type="bar", smooth = false) => {
    return{
        title: "Title_"+title,
        label: "Label_"+title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20
    }
}
const defaultData = {
    total: ["-", "-"],
    male: ["-", "-"],
    female: ["-", "-"],
    adult: ["-", "-"],
    kids: ["-", "-"],
    mask: ["-", "-"],
    nomask: ["-", "-"],
}
const CompareMultiple = () => {
    var comparison = useSelector(state => state.compare.groupComparison);
    var filter = useSelector(state => state.compare.filter);
    const getTitles = (type) => {
        switch(type){
            case 'daily':
                return ["Daily Comparison", moment(new Date(filter.date)).format("LL"), moment(new Date(filter.cdate)).format("LL")];
            case 'weekly':
                return ["Weekly Comparison", filter.week.label + " ("+ filter.year.label + ")", filter.week.label + " ("+ filter.year.label + ")"];
            case 'monthly':
                return ["Monthly Comparison", filter.month.label + " ("+ filter.year.label + ")", filter.month.label + " ("+ filter.year.label + ")"];
            case 'yearly':
                return ["Yearly Comparison", filter.year.label, filter.year.label];
            default:
                return ["", "", ""];
        }
    }

    const getTemplate = (first, second) => {
        var total = first + second;
        var firstLarge = first > second;
        var delta = firstLarge? first-second : second-first;
        var percent = 0;
        var response = {first: null, second: null};
        
        var denom = (firstLarge?second:first);
        if(denom === 0){
            percent = "N/A";
        }else{
            percent = Math.round((delta*100)/(denom===0?delta:denom));
        }
        response.first =    <>
                                <h5>
                                <span className={`badge ${(percent === "N/A" || percent === 0)? "badge-soft-warning" : !firstLarge? "badge-soft-danger" : "badge-soft-success"} rounded-capsule mr-2`}>
                                    {!(percent === "N/A" || percent === 0) && <FontAwesomeIcon icon={!firstLarge?"arrow-down" : "arrow-up"}/>} {percent} {percent === "N/A" ? "" : "%"}
                                </span>
                                {first}</h5>
                            </>
        response.second =    <>
                                <h5>{second}
                                <span className={`badge ${(percent === "N/A" || percent === 0)? "badge-soft-warning" : firstLarge? "badge-soft-danger" : "badge-soft-success"} rounded-capsule ml-2`}>
                                    {!(percent === "N/A" || percent === 0) && <FontAwesomeIcon icon={firstLarge?"arrow-down" : "arrow-up"}/>} {percent} {percent === "N/A" ? "" : "%"}
                                </span>
                                </h5>
                            </>
        return response;
    }

    const [titles, setTitles] = useState(["", "", ""]);
    const [data, setData] = useState(defaultData);
    const [graph, setGraph] = useState(null);
    useEffect(()=>{
        var _titles = getTitles(filter.type.value);
        setTitles(_titles);
        var first = comparison?comparison[0].length? comparison[0][0]: null: null;
        var second = comparison?comparison[1].length? comparison[1][0]: null: null;
        var _data = defaultData;
        _data.total[0] = first? first.total : 0;
        _data.total[1] = second? second.total : 0;
        var temp = getTemplate(_data.total[0], _data.total[1]);
        _data.total = [temp.first, temp.second];
        _data.male[0] = first? first.male : 0;
        _data.male[1] = second? second.male : 0;
        var temp = getTemplate(_data.male[0], _data.male[1]);
        _data.male = [temp.first, temp.second];

        _data.female[0] = first? first.female : 0;
        _data.female[1] = second? second.female : 0;
        var temp = getTemplate(_data.female[0], _data.female[1]);
        _data.female = [temp.first, temp.second];

        _data.adult[0] = first? first.adult : 0;
        _data.adult[1] = second? second.adult : 0;
        var temp = getTemplate(_data.adult[0], _data.adult[1]);
        _data.adult = [temp.first, temp.second];

        _data.kids[0] = first? first.kid : 0;
        _data.kids[1] = second? second.kid : 0;
        var temp = getTemplate(_data.kids[0], _data.kids[1]);
        _data.kids = [temp.first, temp.second];

        _data.mask[0] = first? first.mask : 0;
        _data.mask[1] = second? second.mask : 0;
        var temp = getTemplate(_data.mask[0], _data.mask[1]);
        _data.mask = [temp.first, temp.second];

        _data.nomask[0] = first? first.noMask : 0;
        _data.nomask[1] = second? second.noMask : 0;
        var temp = getTemplate(_data.nomask[0], _data.nomask[1]);
        _data.nomask = [temp.first, temp.second];
        var temp = [
            [first? first.total : 0, first? first.male : 0, first? first.female : 0, first? first.adult : 0, first? first.kid : 0, first? first.mask : 0, first? first.noMask : 0],
            [second? second.total : 0, second? second.male : 0, second? second.female : 0, second? second.adult : 0, second? second.kid : 0, second? second.mask : 0, second? second.noMask : 0]
        ]
        var graphData = [
            tempSeries(temp[0], graphColors.primary, _titles[1]),
            tempSeries(temp[1], graphColors.secondary, _titles[2])
        ]
        setGraph(graphData);

        setData(_data);

    }, [comparison]);

    return (
        <React.Fragment>
            {
                comparison === null && 
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <h4 className="m-0 p-0 text-center">Apply some filters to get data. Nothing to display</h4>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            {
                comparison !== null && 
                <>
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="m-0 p-0">{titles[0]}</h4>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col md={5} className="text-right">
                                            <h5>{filter.store.label}</h5>
                                            <strong>{titles[1]}</strong>
                                        </Col>
                                        <Col md={2} className="text-center"><strong>VS</strong></Col>
                                        <Col md={5}>
                                            <h5>{filter.cstore.label}</h5>
                                            <strong>{titles[2]}</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.total[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Total</strong></Col>
                                        <Col md={5}>{data.total[1]}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.male[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Male</strong></Col>
                                        <Col md={5}>{data.male[1]}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.female[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Female</strong></Col>
                                        <Col md={5}>{data.female[1]}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.adult[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Adults</strong></Col>
                                        <Col md={5}>{data.adult[1]}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.kids[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Kids</strong></Col>
                                        <Col md={5}>{data.kids[1]}</Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md={5} className="text-right">{data.mask[0]}</Col>
                                        <Col md={2} className="text-center"><strong>Masks</strong></Col>
                                        <Col md={5}>{data.mask[1]}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} className="text-right">{data.nomask[0]}</Col>
                                        <Col md={2} className="text-center"><strong>No Masks</strong></Col>
                                        <Col md={5}>{data.nomask[1]}</Col>
                                    </Row> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <LineGraph seriesData={{data: graph, labels: ["Total", "Males", "Females", "Adults", "Kids"]}} hideControls={true}/>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment>
    )
}

export default CompareMultiple;
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import CardHeader from 'reactstrap/lib/CardHeader';
import StatesTable from '../components/production/StatesTable';

const States = () => {
  const [isSelected, setIsSelected] = useState(false);
  return(
    <Card className="mb-3">
        <CardHeader>

        </CardHeader>
        <CardBody className="p-0">
          <StatesTable setIsSelected={setIsSelected} />
        </CardBody>
      </Card>
  );
};

export default States;

import { filter } from 'lodash';
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paging = (props) => {
    return (
        <Pagination aria-label="Pagination">
            <PaginationItem>
                <PaginationLink first onClick={()=>props.setPage(1)}/>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink previous onClick={()=>props.setPage(props.page-1)} disabled={props.page<=1}/>
            </PaginationItem>
            {
                new Array(props.pages).fill(1).map((val, index) => {
                    return (
                        <PaginationItem key={"paging_"+index} className={ props.page == index + 1 ? 'active' : '' }>
                            <PaginationLink style={props.page == index + 1 ?{background: "#d51c29", border: "1px solid #d51c29"}: {}} onClick={()=>props.setPage(index+1)} color="danger" className="btn-danger">
                                {index + 1}
                            </PaginationLink>
                        </PaginationItem>
                    )
                })
            }
            <PaginationItem>
                <PaginationLink next onClick={()=>props.setPage(props.page+1)} disabled={props.page>=props.pages}/>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink last onClick={()=>props.setPage(props.pages)}/>
            </PaginationItem>
        </Pagination>
    );
}

export default Paging;
import React, { Fragment, useState, useEffect } from 'react';
import PageHeader from '../components/common/PageHeader';
import {Card, CardBody, Row, Col, Progress, Button} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import { useSelector, useDispatch } from 'react-redux';
import { categoryRequest, categoryGraphRequest, vehicleRequest, vehicleGraphRequest } from '../helpers/requests';
import Sections from './template/Sections';
import FormFilter from './template/FormFilter';
import HeaderFilter from './template/HeaderFilter';
import moment from 'moment';
import LineGraph from '../components/production/LineGraph';
import Table from 'reactstrap/lib/Table';
import CategoryCard from './template/CategoryCard';
import { setInsightFilter } from '../redux/actions/insightActions';
import { setVehicleFilter, setVehicleLoader } from '../redux/actions/vehicleActions';
import Select from 'react-select';
import { graphColors } from '../helpers/utils';
import dye from '../assets/dye.png';
import base from '../assets/base.jpg';
import { Circle, CheckCircle } from 'react-feather';

const customStyles = {
  option: (provided, state) => ({
      ...provided,
      background: state.isSelected?graphColors.primary:'',
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "0px",
  }),
  control: (provided) => ({
      ...provided,
      height: "29px",
      background: "#FFFFFF",
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "8px",
      border: "1px solid transparent"
  }),
  menu: (provided) => ({
      ...provided,
      zIndex: 10
  })
}

const randomNumber = (range) => {
  return Math.floor(Math.random()*range);
}

// const vehiclesData = {
//   visitors: [
//     {label: "Front", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Back", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Left", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Right", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)}
//   ],
//   prospects: [
//     {label: "Front", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Back", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Left", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)},
//     {label: "Right", total: randomNumber(200), male: randomNumber(200), female: randomNumber(200), adult: randomNumber(200), kid: randomNumber(200), dwelltime: randomNumber(200)}
//   ]
// }

const options = [
  {value: "visitors", label: "Visitor's Impressions"},
  {value: "prospects", label: "Prospect's Impressions"}
]

const Vehicles = () => {
  const dispatch = useDispatch();
  const labels = useSelector((state)=> state.vehicle.labels);
  const vehiclesData = useSelector((state)=> state.vehicle.data);
  const graph = useSelector((state)=> state.vehicle.graph);
  var filter = useSelector((state)=> state.vehicle.filter);
  var loader = useSelector((state)=> state.vehicle.loader);
  const user = useSelector((state)=> state.auth.user);
  const [option, setOption] = useState(options[0]);
  const [title, setTitle] = useState("Front Side");
  const [comparisonType, setComType] = useState("front");
  const [viewBy, setViewBy] = useState("vehicle");
  const [currentSummary, setCurrentSummary] = useState(vehiclesData.visitors[0]);
  const [showMap, setShowMap] = useState(true);
  const stores = user.access.map((val, index)=>{
    return {...val, value: val._id, label: val.label};
  });

  const setComparisonType = (tag, index, label) => {
    setComType(tag);
    setCurrentSummary(vehiclesData[option.value][index]);
    setTitle(label);
  }

  const setOptionValue = (_option) => {
    var index = comparisonType == "front" ? 0 : comparisonType == "back" ? 1 : comparisonType == "left" ? 2 : comparisonType == "right" ? 3: 0;
    setOption(_option);
    setCurrentSummary(vehiclesData[_option.value][index])
  }

  var floors = [];
  if(stores.length){
    floors = stores[0].floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
  }

  var sections = [];
  if(floors.length){
    sections = floors[0].sections.map((val, index)=>{
      return {...val, value: val._id};
    });
  }

  const setSelected = (selection) =>{
    setType(selection);
    var startDate;
    var endDate;
    try{
      if(selection === "today"){
          startDate = moment(new Date()).startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection === "yesterday"){
          startDate = moment(new Date()).subtract(1, "day").startOf("day");
          endDate = moment(new Date()).subtract(1, "day").endOf("day");
      }else if(selection === "this_week"){
          startDate = moment(new Date()).subtract(6, "day").startOf("day");
          endDate = moment(new Date()).endOf("day");
      }else if(selection === "this_month"){
        startDate = moment(new Date()).subtract(30, "day").startOf("day");
        endDate = moment(new Date()).endOf("day");
      }
      dispatch(setVehicleFilter({...filter, start: startDate, end: endDate, type: selection}));
      getDwelltime(filter.floor._id, filter.store._id, filter.section._id,startDate, endDate);
    }catch(e){
    }
  }
  const setStartDate = (startDate) =>{
    dispatch(setVehicleFilter({...filter, start: startDate, type: null}));
  }
  const setEndDate = (endDate) =>{
    dispatch(setVehicleFilter({...filter, end: endDate, type: null}));
  }
  const setStore = (store) =>{
    let tempFloors = store.floors.map((val, index)=>{
      return {...val, value: val._id, label: val.floor_label};
    });
    let tempSections = tempFloors[0].sections.map((val, index)=>{
      return {...val, value: val._id, label: val.label};
    });
    loadNewMap();
    dispatch(setVehicleFilter({...filter, store: store, stores: stores, floor: tempFloors[0], floors: tempFloors, section: tempSections[0], sections: tempSections, type: null}));
  }
  const setFloor = (floor) =>{
    let tempSections = floor.sections.map((val, index)=>{
      return {...val, value: val._id, label: val.label};
    });
    loadNewMap();
    dispatch(setVehicleFilter({...filter, floor: floor, section: tempSections[0], sections: tempSections, type: null}));
  }
  const setSection = (section) =>{
    getDwelltime(filter.floor._id, filter.store._id, section._id);
    dispatch(setVehicleFilter({...filter, section: section, type: null}));
  }

  const setType = (type) => {
    dispatch(setVehicleFilter({...filter, type: null}));
  }

  const loadNewMap = () => {
    setShowMap(false);
    setTimeout(()=>{
      setShowMap(true);
    }, 200)
  }
  const getBarLabels = () => {
    return filter.sections.map((val, ind)=>{
        return val.label;
    });
  }

  const getDwelltime = (fid=null, sid=null, sec_id=null, start = null, end=null) => {
    var requestData = {
      params: {
        fid: fid?fid:filter.floor? filter.floor.value : floors[0].value,
        sid: sid?sid:filter.store? filter.store.value: stores[0].value, 
        sec_id: sec_id?sec_id:filter.section? filter.section.value: sections.length?sections[0].value:null, 
        start: start != null ? start : filter.start,
        end: end != null ? end : filter.end
      }, 
      dispatch: dispatch
    }
    var requestDataFootfall = {
      params: {
        fid: fid?fid:filter.floor? filter.floor.value : floors[0].value,
        sid: sid?sid:filter.store? filter.store.value: stores[0].value, 
        sec_id: sec_id?sec_id:filter.section? filter.section.value: sections.length?sections[0].value:null,
        start: start != null ? start : filter.start,
        end: end != null ? end : filter.end
      }, 
      dispatch: dispatch
    }
    dispatch(setVehicleLoader(true));
    vehicleRequest(requestDataFootfall);
    vehicleGraphRequest(requestData);
    //heatmapRequest({params: {sid: optionSelected? optionSelected.value: spaces[0].value, fid: floor?floor.value: floors? floors[0].value: null, start: new Date(), end: new Date()}, dispatch: dispatch});
  }

  useEffect(()=>{
    var index = comparisonType == "front" ? 0 : comparisonType == "back" ? 1 : comparisonType == "left" ? 2 : comparisonType == "right" ? 3: 0;
    setCurrentSummary(vehiclesData[option.value][index])
  },[vehiclesData]);

  useEffect(()=>{
    setStore(stores.length?stores[0]:null);
    setTimeout(()=>{
      getDwelltime();
    }, 200);
  }, []);

  return (
    <Fragment>
      <Row noGutters>
        <Col>
          <h4 className="my-3">Vehicles Statistics</h4>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <HeaderFilter icon="star" title={filter.store ? filter.store.label : ""} value={""} selected={filter.type} setSelected={(val) => setSelected(val)}/>
        </Col>
      </Row>
      <FormFilter filter={filter} setStore={(e)=>setStore(e)} setFloor={(e)=>setFloor(e)} setStartDate={(e)=>setStartDate(e)} setEndDate={(e)=>setEndDate(e)} submit={()=>getDwelltime()}/>
      <Row>
        <Col style={{height: "20px"}}>
        {
          loader &&
          <Progress animated className="mb-2" color="danger" value="100" style={{ height: '5px'}}/>
        }
       </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12} xs={12}>  
          {
            filter.store && showMap &&
            <Row>
              <Col md={12} className="mb-3">
                <h4>View Statistics By</h4>
              </Col>
              <Col md={12} className="mb-3">
                <Button color={viewBy === 'vehicle' ? "danger" : "secondary"} className="btn-sm mr-2" onClick={()=>setViewBy("vehicle")}>Vehicles</Button> 
                <Button color={viewBy === 'slot' ? "danger" : "secondary"} className="btn-sm" onClick={()=>setViewBy("slot")}>Slots</Button>
              </Col>
              <Col md={12} className="mb-3">
                <h4>Select {viewBy == "vehicle" ? 'Vehicle' : 'Slot'}</h4>
              </Col>
              {
                filter.sections.map((section, index)=>{
                  if(section.type !== "item"){
                    return <></>
                  }
                  return (
                    <Col md={6} className="mb-3" style={{cursor: "pointer"}} onClick={()=>setSection(section)} key={"veh-"+index}>
                      <Card>
                        <CardBody>
                            {
                              section._id === filter.section._id &&
                              <CheckCircle color={graphColors.primary}/>
                            }
                            {
                              section._id !== filter.section._id &&
                              <Circle />
                            }
                            <span color={section._id === filter.section._id?graphColors.primary: "black"} className="mx-4">{viewBy == "vehicle" ? section.label : section.slot}</span>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          }
        </Col>
      {/* <Row className="mb-3">
        <Col>
          <Table>
            <thead>
              <tr><th></th><th>Total</th><th>Males</th><th>Females</th><th>Adults</th><th>Kids</th></tr>
            </thead>
            <tbody>
              {
                vehiclesData.map((row, index)=>{
                  return <tr key={"table_row_"+index}><th>{row.label}</th><td>{row.total}</td><td>{row.male}</td><td>{row.female}</td><td>{row.adult}</td><td>{row.kid}</td></tr>
                })
              }
            </tbody>
          </Table>
        </Col>
      </Row> */}
      <Col lg={6} md={12} sm={12} xs={12}>
      { vehiclesData && filter.section != null &&
        <Fragment>
          <Row className="mb-3">
            <Col md={12}>
              <h4>{viewBy == "vehicle" ? filter.section.label : filter.section.slot} | {title} {option.label}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-left mb-3">
              <Select
                value={option}
                onChange={(value)=>setOptionValue(value)}
                options={options}
                styles={customStyles}
              ></Select>
            </Col>
            <Col className="pt-2">
              <Button color={comparisonType === 'front' ? "danger" : "secondary"} className="btn-sm mx-1 mb-3" onClick={()=>setComparisonType("front", 0, "Front Side")}>Front</Button>
              <Button color={comparisonType === 'back' ? "danger" : "secondary"} className="btn-sm mx-1 mb-3" onClick={()=>setComparisonType("back", 1, "Back Side")}>Back</Button>
              <Button color={comparisonType === 'left' ? "danger" : "secondary"} className="btn-sm mx-1 mb-3" onClick={()=>setComparisonType("left", 2, "Left Side")}>Left</Button>
              <Button color={comparisonType === 'right' ? "danger" : "secondary"} className="btn-sm mx-1 mb-3" onClick={()=>setComparisonType("right", 3, "Right Side")}>Right</Button>
            </Col>
          </Row>

          <Row noGutters>
            <Col>
              <div className="card-deck">
                <CategoryCard title="Total" value={currentSummary.total} icon="user"></CategoryCard>
                <CategoryCard title="Avg Dwell Time" value={<>{currentSummary.dwelltime} <small>sec</small></>} icon="clock"></CategoryCard>
              {/* </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <div className="card-deck"> */}
              </div>

              <div className="card-deck">
                <CategoryCard title="Males" value={currentSummary.male} icon="male"></CategoryCard>
                <CategoryCard title="Females" value={currentSummary.female} icon="female"></CategoryCard>
                {/* <CategoryCard title="Kids" value={currentSummary.kid} icon="child"></CategoryCard>
                <CategoryCard title="Adults" value={currentSummary.adult} icon="male"></CategoryCard> */}
              </div>
            </Col>
          </Row>
        </Fragment>
      }
      </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <h4>Vechicle's Location Map</h4>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
            <Card>
                <CardBody>
                  {filter.store && showMap &&
                    <Sections setSection={(section)=>setSection(section)} show="item" dye={filter.floor? filter.floor.dye_image : ""} map={filter.floor? filter.floor.image : ""} sections={filter.sections?filter.sections:[]} section={filter.section}/>
                  }
                </CardBody>
            </Card>
        </Col>
      </Row>
      {false && <>
        <PageHeader
          title="Category wise statistics are not yet activated"
          description="
          Category wise statistics provide you information about how individual sections are performing.
          This in turn helps you to indentify re arranging your product displays.
          "
          className="mb-3"
        >
        </PageHeader>
        <Card>
          <FalconCardHeader title="Sample" />
          <CardBody>
            <Row>
              <Col md={8}>
                {/* <img src={sections} className="rounded w-100" /> */}
              </Col>
              <Col className="h-100 valign-middle">
              </Col>
            </Row>
            <Row>
              <Col className="h-100 valign-middle">
              </Col>
              <Col md={8}>
                {/* <img src={sectionStates} className="rounded w-100" /> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>}
    </Fragment>
  );
}
export default Vehicles;
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import Summary from '../pages/Summary';
import Footfall from '../pages/Footfall';
import Realtime from '../pages/Realtime';
import Heatmaps from '../pages/Heatmaps';
import Categories from '../pages/Categories';
import Conversion from '../pages/Conversion';
import Forecasting from '../pages/Forecasting';
import Insights from '../pages/Insights';
import Comparison from '../pages/Comparison';
import Reporting from '../pages/Reporting';
import Settings from '../pages/Settings';
import Stores from '../pages/Stores';
import States from '../pages/States';
import WalkRoute from '../pages/WalkRoute';
import Vehicles from '../pages/Vehicles';
import Detections from '../pages/Detections';
import EditSections from '../pages/EditSections';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/visitors" exact component={Footfall} />
            <Route path="/realtime" exact component={Realtime} />
            <Route path="/heatmaps" exact component={Heatmaps} />
            <Route path="/vehicles" exact component={Vehicles} />
            <Route path="/alerts" exact component={Detections} />
            <Route path="/conversion" exact component={Conversion} />
            <Route path="/journey" exact component={WalkRoute} />
            <Route path="/forcasting" exact component={Forecasting} />
            <Route path="/insights" exact component={Insights} />
            <Route path="/comparison" exact component={Comparison} />
            <Route path="/reporting" exact component={Reporting} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/stores/state/:id" exact component={States} />
            <Route path="/pages/edit-sections" exact component={EditSections} />
            <Route path="/stores" exact component={Stores} />
            <Route path="/" exact component={Summary} />
            <DashboardRoutes />
          </Switch>
          {!isKanban && <Footer />}
        </div>
        {/* <SidePanelModal path={location.pathname} /> */}
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;

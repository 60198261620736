import React from 'react';
import PropTypes from 'prop-types';
import { Button, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import Verified from '../common/Verified';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../common/Avatar';
import StoreSvg from '../../assets/img/store.svg';
import FalconAccordions from '../bootstrap-components/FalconAccordions';

const StoreSummary = ({label, address, city, state, country, contact, floors, cameras}) => (
  <Media>
    <Link to={"#"} className="text-danger">
      {StoreSvg ? <img className="img-fluid" src={StoreSvg} width={56} alt="" /> : <Avatar name={label} size="3xl" />}
    </Link>
    <Media body className="position-relative pl-3 btn-reveal-trigger">
      <h6 className="fs-0 mb-0 d-flex justify-content-between align-items-start">
        <Link to={"#"} className="text-danger">
          {label} <Verified />
        </Link>
        <Link to="edit-sections" className='btn btn-sm btn-outline-danger' style={{float: "right"}}>Edit Sections</Link>
      </h6>
      <p className="mb-1">{address}, {city}, {country}</p>
      <p className="text-1000 my-1">Contact: {contact}</p>
      <span className="badge badge-soft-danger mr-2">Floors: {floors.length}</span>
      <span className="badge badge-soft-success mr-2">Cameras: {cameras}</span>
      <hr className="border-dashed border-bottom-0" />
    </Media>
  </Media>
);

StoreSummary.propTypes = {
  label: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  contact: PropTypes.string,
  created_at: PropTypes.string,
  floors: PropTypes.array,
  features: PropTypes.object,
  cameras: PropTypes.number,
  type: PropTypes.string
};

StoreSummary.defaultProps = {
  divider: true,
  verified: false
};

export default StoreSummary;

export default {
    totalStores: 2252,
    totalEmployes: 270000,
    averageStoreSize: 47060,
    stores: [
        {state:"California", stores:[], total:592},
        {state:"Washington", stores:[], total:219},
        {state:"Texas", stores:[], total:208},
        {state:"Illinois", stores:[], total:183},
        {state:"Arizona", stores:[], total:134},
        {state:"Oregon", stores:[], total:122},
        {state:"Colorado", stores:[], total:105},
        {state:"Massachusetts", stores:[], total:76},
        {state:"New Jersey", stores:[], total:73},
        {state:"Maryland", stores:[], total:65},
        {state:"Pennsylvania", stores:[], total:50},
        {state:"Nevada", stores:[], total:50},
        {state:"Idaho", stores:[], total:42},
        {state:"Virginia", stores:[], total:38},
        {state:"Montana", stores:[], total:38},
        {state:"New Mexico", stores:[], total:34},
        {state:"Alaska", stores:[], total:26},
        {state:"New Hampshire", stores:[], total:26},
        {state:"Hawaii", stores:[], total:23},
        {state:"Maine", stores:[], total:21},
        {state:"Vermont", stores:[], total:19},
        {state:"Delaware", stores:[], total:18},
        {state:"New York", stores:[], total:16},
        {state:"Louisiana", stores:[], total:16},
        {state:"Wyoming", stores:[], total:14},
        {state:"District of Columbia", stores:[], total:11},
        {state:"Rhode Island", stores:[], total:8},
        {state:"Utah", stores:[], total:6},
        {state:"Nebraska", stores:[], total:5},
        {state:"Connecticut", stores:[], total:4},
        {state:"Indiana", stores:[], total:4},
        {state:"South Dakota", stores:[], total:3},
        {state:"North Dakota", stores:[], total:1},
        {state:"Iowa", stores:[], total:1},
        {state:"Arkansas", stores:[], total:1}
    ]
}
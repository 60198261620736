import { login } from '../redux/actions/authActions';
import { setSummary, setSummaryLoader } from '../redux/actions/summaryActions';
import { setFootfall, setFootfallGraph, setFootfallLoader } from '../redux/actions/footfallActions';
import { setHeatmap, setHeatmapLoader } from '../redux/actions/heatmapActions';
import { setInsight, setInsightProgress } from '../redux/actions/insightActions';
import { setCategory, setCategoryGraph, setCategoryLoader } from '../redux/actions/categoryActions';
import { setComparison, setComparisonLoader, setGroupComparison } from '../redux/actions/comparisonActions';
import { setVehicle, setVehicleGraph, setVehicleLoader } from '../redux/actions/vehicleActions';
import { setDetection, setDetectionFilter, setDetectionLoader, setDetectionOld, setDetectionUpdate } from '../redux/actions/detectionActions';

export const baseUrl = "https://api.redseadigital.ai/";
// const baseUrl = "http://3.212.42.202:4300/";
const localUser = localStorage.getItem("user");
var user = localUser ? JSON.parse(localUser) : null;

export const loginRequest = (requestData) => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({email: requestData.email, password: requestData.password}),
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer 12345"},
    };

    fetch(baseUrl+"users/authenticate", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if(data.status === true){
          user = data.data;
          localStorage.setItem("user", JSON.stringify(data.data));
          requestData.dispatch(login(data.data));
          setTimeout(()=>{
            requestData.setRedirect(true);
          }, 200);
        }
    }).catch(err =>{
      console.warn(err);
    });
};

export const summaryRequest = (requestData) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
    };

    fetch(baseUrl+"footfall/summary", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setSummaryLoader(false));
        if(data.status === true){
          requestData.dispatch(setSummary(data.data));
        }
    }).catch(err =>{
      requestData.dispatch(setSummaryLoader(false));
      console.warn(err);
    });
};

export const footfallRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"footfall/getCount", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setFootfall(data.data));
      }
    requestData.dispatch(setFootfallLoader(false));
  }).catch(err =>{
    console.warn(err);
    requestData.dispatch(setFootfallLoader(false));
  });
};

export const footfallGraphRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"footfall/lineGraph", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setFootfallGraph(data.data));
      }
  }).catch(err =>{
    console.warn(err);
  });
};

export const heatmapRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"heatmap/getHeatmap", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      requestData.dispatch(setHeatmapLoader(false));
      if(data.status === true){
        requestData.dispatch(setHeatmap(data.data));
      }
  }).catch(err =>{
    requestData.dispatch(setHeatmapLoader(false));
    console.warn(err);
  });
};

export const categoryRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"dwelltime/getCount", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setCategory(data.data));
      }
    requestData.dispatch(setCategoryLoader(false));
  }).catch(err =>{
    console.warn(err);
    requestData.dispatch(setCategoryLoader(false));
  });
};

export const categoryGraphRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"dwelltime/lineGraph", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setCategoryGraph(data.data));
      }
  }).catch(err =>{
    console.warn(err);
  });
};

export const vehicleRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"vehiclesdt/getCount", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setVehicle(data.data));
      }
    requestData.dispatch(setVehicleLoader(false));
  }).catch(err =>{
    console.warn(err);
    requestData.dispatch(setVehicleLoader(false));
  });
};

export const vehicleGraphRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"vehiclesdt/lineGraph", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        requestData.dispatch(setVehicleGraph(data.data));
      }
  }).catch(err =>{
    console.warn(err);
  });
};

export const insightRequest = (requestData) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"footfall/insights", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      requestData.dispatch(setInsightProgress(false));
      if(data.status === true){
        requestData.dispatch(setInsight(data.data));
      }
  }).catch(err =>{
    requestData.dispatch(setInsightProgress(false));
    console.warn(err);
  });
};



export const comparisonRequest = (requestData) => {
  requestData.dispatch(setComparisonLoader(true));
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"footfall/compare", requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        if(requestData.params.type==="single"){
          requestData.dispatch(setComparison(data.data));
        }else{
          requestData.dispatch(setGroupComparison(data.data));
        }
      }else{
        requestData.dispatch(setComparisonLoader(false));
      }
  }).catch(err =>{
    requestData.dispatch(setComparisonLoader(false));
    console.warn(err);
  });
};


export const detectionRequest = (requestData) => {
  requestData.dispatch(setDetectionLoader(true));
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"detections?"+ new URLSearchParams(requestData.params), requestOptions).then(res => {
      return res.json();
  }).then(data => {
      if(data.status === true){
        if(requestData.type==="new"){
          requestData.dispatch(setDetection(data.data.docs));
        }else{
          requestData.dispatch(setDetectionOld(data.data.docs));
        }
        requestData.dispatch(setDetectionFilter({page: data.data.page, pages: data.data.pages, limit: data.data.limit, total: data.data.total}))
      }else{
        requestData.dispatch(setDetectionLoader(false));
      }
  }).catch(err =>{
    requestData.dispatch(setDetectionLoader(false));
    console.warn(err);
  });
};

export const updateDetectionRequest = (requestData) => {
  requestData.dispatch(setDetectionLoader(true));
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(requestData.params),
    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+ user.token},
  };

  fetch(baseUrl+"detections/"+ requestData.params._id, requestOptions).then(res => {
      return res.json();
  }).then(data => {
  }).catch(err =>{
  });
};
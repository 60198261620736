import {actionTypes} from '../constants/action-types'

export const setHeatmap = (payload) => {
    return {
        type: actionTypes.SET_HEATMAP,
        payload: payload
    }
}

export const setHeatmapFilter = (payload) => {
    return {
        type: actionTypes.SET_HEATMAP_FILTER,
        payload: payload
    }
}

export const setHeatmapLoader = (payload) => {
    return {
        type: actionTypes.SET_HEATMAP_LOADER,
        payload: payload
    }
}


import {actionTypes} from '../constants/action-types'

export const setSummary = (payload) => {
    return {
        type: actionTypes.SET_SUMMARY,
        payload: payload
    }
}

export const setSummaryFilter = (payload) => {
    return {
        type: actionTypes.SET_SUMARY_FILTER,
        payload: payload
    }
}

export const setCurrentSummary = (payload) => {
    return {
        type: actionTypes.SET_CURRENT_SUMARY,
        payload: payload
    }
}

export const setSummaryLoader = (payload) => {
    return {
        type: actionTypes.SET_SUMARY_LOADER,
        payload: payload
    }
}


import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { graphColors } from '../../helpers/utils';
import Select from 'react-select';
import Datetime from 'react-datetime';

const inputStyle = () => { 
    return {
        background: "#FFFFFF",
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "8px",
        border: "1px solid transparent"
    }
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected?graphColors.primary:'',
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "0px",
    }),
    control: (provided) => ({
        ...provided,
        height: "40px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
        borderRadius: "8px",
        border: "1px solid transparent"
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 10
    })
}

class FormFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <Row noGutters className="mb-2">
                <Col md={3}>
                    <Select
                        value={this.props.filter.store}
                        onChange={value=>this.props.setStore(value)}
                        options={this.props.filter.stores}
                        labelledBy="Store"
                        className="mr-md-2 mb-1 react-select"
                        styles={customStyles}
                    />
                </Col>
                {
                    this.props.filter.floors.length > 1 &&
                    <Col md={2}>
                        <Select
                        value={this.props.filter.floor}
                        onChange={value=>this.props.setFloor(value)}
                        options={this.props.filter.floors}
                        labelledBy="Floor"
                        className="mr-md-2 mb-1 react-select"
                        styles={customStyles}
                        />
                    </Col>
                }
                <Col md={2}>
                <Datetime
                    inputProps={{"placeholder":"Start Date"}}
                    timeFormat={false}
                    value={this.props.filter.start}
                    onChange={this.props.setStartDate}
                    className="mr-md-2 mb-1 filter-date"
                />
                </Col>
                <Col md={2}>
                <Datetime
                    inputProps={{"placeholder":"End Date"}}
                    timeFormat={false}
                    value={this.props.filter.end}
                    onChange={this.props.setEndDate}
                    className="mr-md-2 mb-1 filter-date"
                />
                </Col>
                <Col className="text-right">
                    <Button className="px-5 mb-1 filter-button" color="danger" onClick={()=>this.props.submit()}>
                        Search
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default FormFilter;